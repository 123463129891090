"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true

###
Friends Search Component
###

Vue.component 'search-friend',
  template: '#search-friend-template'

  data: ->
    term: ''
    response: {}
    page: 1
    pagesize: 5
    last_page: 1

  created: () ->
    this.getAllFriends()
    return

  methods:

    getAllFriends: () ->
      _this = this
      nanobar.go 50
      $.ajax
        type: "GET"
        url: '/friends/browse?page=' + _this.page + '&limit=' + _this.pagesize
        success: (data) ->
          nanobar.go 100
          console.log(data)
          if data
            _this.response = data
            _this.page = data.current_page
            _this.last_page = data.last_page
        error: (response) ->
          console.log(response)
      return false

    getFriend: (fid) ->
      _this = this
      nanobar.go 50
      $.ajax
        type: "GET"
        url: '/friends/search?q=' + _this.term + '&page=' + _this.page + '&limit=' + _this.pagesize
        success: (data) ->
          nanobar.go 100
          if data
            _this.response = data
            _this.page = data.current_page
            _this.last_page = data.last_page
        error: (response) ->
          console.log(response)
      return false

    searchFriend: (fid) ->
      _this = this
      nanobar.go 50
      console.log('/friends/browse?page=' + _this.page + '&limit=' + _this.pagesize)
      $.ajax
        type: "GET"
        url: '/friends/search?q=' + _this.term + '&page=' + _this.page + '&limit=' + _this.pagesize
        success: (data) ->
          console.log(data)
          if data
            _this.response = data
            nanobar.go 100
            _this.page = data.current_page
            _this.last_page = data.last_page
        error: (response) ->

      return false


    paginationStart: () ->
      if this.page > 3
        if this.page + 2 > this.last_page
          if this.last_page <= 4
            return 1
          else
            return this.last_page - 4
        else
          return this.page - 2
      else
        return 1

    paginationEnd: () ->
      pgend = this.paginationStart() + 4
      if pgend > this.last_page
        pgend = this.last_page
      return pgend

    pageNumbers: () ->
      start = this.paginationStart()
      end = this.paginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    getPage: (page) ->
      this.page = page
      if this.term
        this.searchFriend()
      else
        this.getAllFriends()


###
Add Friends Component
###

Vue.component 'add-friend',
  template: '#add-friend-template'

  data: ->
    success: false,
    isFriend: false,
    isRequested: false

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    addFriend: (fid) ->
      _this = this
      friendId = fid;
      window.nanobar.go 50
      $.ajax
        type: "POST"
        url: '/friends'
        data:
          username: friendId,
          message: 'I want to be your friend!'
        success: (data) ->
          if data.result
            _this.showModal '<div style="text-align:center;"><img style="display: block; width: 200px; margin: 0 auto;" src="/img/icons/activity/cultist.svg"><br /><h3>Requent Sent</h3>Let\'s hope they accept. HAHA HA MUHUHAHAHAHAHA.<div>'
          else
        error: (response) ->
          _this.showModal '<div style="text-align:center;"><img style="display: block; width: 200px; margin: 0 auto;" src="/img/icons/activity/enrage.svg" /><br /><h3>Already Sent</h3>You have already requested this user as your friend or this user denied your request.<div>'
        window.nanobar.go 100
      return false

###
Invite Friends Component
###

Vue.component 'invite-friend',
  template: '#invite-friend-template'

  data: ->
    success: false,
    isFriend: false,
    friend_email: '',
    isError: false

  methods:

    checkEmail: (email) ->
        re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);

    inviteFriend: () ->
      _this = this
      if !_this.friend_email || _this.checkEmail(_this.friend_email) == false
        _this.isError = true
        return false
      $('.ef-loader-bg').addClass 'visible'
      $.ajax
        type: "POST"
        url: '/friends/invite'
        data:
          email: _this.friend_email
        success: (data) ->
          if data.result
            console.log data
            _this.success = true;
          else
          $('.ef-loader-bg').removeClass 'visible'
        error: (response) ->
          $('.ef-loader-bg').removeClass 'visible'
      return false

###
Friends Charts
###

Vue.component 'chart-contests',
  template: '#chart-contests-template'
  props:
    uname: ''

  created: () ->
    this.getChart()
    return

  methods:

    setUpChart: (data) ->
      nv.addGraph ->
        chart = nv.models.lineChart().showLegend(true).showYAxis(true).showXAxis(true)
        chart.xAxis.axisLabel('Count').tickFormat d3.format(',r')
        chart.yAxis.axisLabel('Gem Amount').tickFormat d3.format('.02f')
        myData = data
        d3.select('svg').datum(myData).call chart
        nv.utils.windowResize ->
          chart.update()
          return
        chart

    getChart: () ->
      _this = this
      $.getJSON '/api/profile/bets/' + _this.uname, (data) ->
        items = []
        wonContests = []
        i = 0
        while i < data.length
          console.log data[i]
          wonContests.push
            x: i
            y: parseInt(data[i].amount_won)
          i++
        enteredContests = []
        i = 0
        while i < data.length
          enteredContests.push
            x: i
            y: parseInt(data[i].wager)
          i++

        data = [
          {
            key: 'Won Contests'
            values: wonContests
            color: '#FF1966'
          }
          {
            key: 'Entered Contests'
            values: enteredContests
            color: '#C54915'
          }
        ]
        _this.setUpChart data
        return




###
Add Friend
###
if $('#add-friend').length > 0
  friends = new Vue
    el: '#add-friend'
