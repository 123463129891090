stripeResponseHandler = (status, response) ->
  $form = $('#stripe-payment-form')
  if response.error
    # Show the errors on the form
    $form.find('.payment-errors').css('display', 'block').find('.ef-msg').text response.error.message
    $form.find('button').prop 'disabled', false
  else
    # response contains id and card, which contains additional card details
    token = response.id
    # Insert the token into the form so it gets submitted to the server
    $form.append $('<input type="hidden" name="stripeToken" />').val(token)
    $form.append $('<input type="hidden" name="amount" />').val($('#amount').val())
    # and submit
    $form.get(0).submit()
  return
$ ->
  $('#stripe-payment-form').card
    form: '#stripe-payment-form'
    container: '.card-wrapper'
    formSelectors:
      numberInput: 'input[id="number"]'
      expiryInput: 'input[id="exp-month"], input[id="exp-year"]'
      cvcInput: 'input[id="cvc"]'
      nameInput: 'input[id="name"]'
    width: "100%"
    formatting: true
    messages:
      validDate: 'valid\ndate'
      monthYear: 'mm/yyyy'
    values:
      number: '&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;'
      name: $('#currentUser').val()
      expiry: '&bull;&bull;/&bull;&bull;'
      cvc: '&bull;&bull;&bull;'
    debug: false

  ################ Send Form
  $('#stripe-payment-form').submit (event) ->
    $form = $(this)
    # Disable the submit button to prevent repeated clicks
    $form.find('button').prop 'disabled', true
    Stripe.card.createToken $form, stripeResponseHandler
    # Prevent the form from submitting with the default action
    false

  ################ Calculate Totals
  $('.gold-package').click ->
    type = $(this).data('type')
    amt = $(this).data('amt')
    price = $(this).data('price')
    id = $(this).data('id')
    total = 0
    packages = [0,0]

    if $(this).hasClass('gold-selected')
      $(this).removeClass('gold-selected')

    else
      $(".gold-package[data-type='" + type + "']").each (i) ->
        $(this).removeClass('gold-selected')
        return
      $(this).addClass('gold-selected')

    $(".gold-selected").each (i) ->
      total = total + Math.abs($(this).data('price'))
      if $(this).data('type') == 'coin'
        packages[0] = $(this).data('id')
      if $(this).data('type') == 'cash'
        packages[1] = $(this).data('id')
      return

    $('.package_id').val(packages)
    $('#coin-total').html('$' + total)

    return

  ################ Check year (refactor to include month)
  $('#exp-year').keyup ->
    if $(this).val().length == 4
      inputDate = $('#exp-year').val()
      now = new Date
      year = now.getFullYear().toString()
      if inputDate < year
        BootstrapDialog.show
          title: 'Error'
          message: 'Please Select a Valid Year'
    return
