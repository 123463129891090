"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true

###
Promo Component
###

Vue.component 'promo',
  template: '#promo-template'
  props: ['pid']

  data: ->
    promo: {}

  created: () ->
    this.getPromo()
    return

  computed:

    promo_image: ->
      if this.promo.data.bg_url
        return 'background:url(' + this.promo.data.bg_url + ') no-repeat top right;'

  methods:

    decodeHtml: (html) ->
      txt = document.createElement('textarea')
      txt.innerHTML = html
      txt.value

    updatePromo: (data) ->
      this.promo = data

    getPromo: ->
      _this = this
      $.ajax
        type: "GET"
        url: '/promos/api/' + _this.pid
        success: (data) ->
          if data
            _this.updatePromo data
          else
        error: (response) ->
          console.log response

###
Challenger promoboxes component
###

Vue.component 'challenger-promo-list',
  template: '#challenger-promo-list-template'
  props: ['username', 'game']
  name: 'challenger-promo-list'

  data: ->
    challenger_featured: {}

  created: () ->
    this.getFeaturedChallenger()
    return

  methods:

    updateFeaturedChallenger: (data) ->
      this.challenger_featured = data

    getFeaturedChallenger: () ->
      _this = this
      $.ajax
        type: "GET"
        url: '/challenger/list?page=1&limit=3&search=&not_status=closed'
        success: (data) ->
          if data
            _this.updateFeaturedChallenger data
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response

###
Tournaments promoboxes component
###

Vue.component 'tournaments-promo-list',
  template: '#tournaments-promo-list-template'
  name: 'tournaments-promo-list'

  data: ->
    tournaments_featured: {}

  created: () ->
    this.getFeaturedTournaments()
    return

  methods:

    updateFeaturedTournaments: (data) ->
      _this = this
      this.tournaments_featured = data
      Vue.nextTick () ->
        $('.c-timer').each ->
          $this = $(this)
          finalDate = moment($(this).data('countdown')).toDate()
          $this.countdown finalDate, (event) ->
            totalHours = event.offset.totalDays * 24 + event.offset.hours
            if event.offset.seconds != 0 && event.offset.minutes != 0
              $(this).html event.strftime(totalHours + ' hr %M min %S sec')
            else
              $(this).html 'Started'
            return
          return

    getFeaturedTournaments: () ->
      _this = this
      $.ajax
        type: "GET"
        url: '/bracketsapi/all/page/1/limit/3/status/all?game=all'
        success: (data) ->
          if data
            _this.updateFeaturedTournaments data
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response


###
Marketplace promoboxes component
###

Vue.component 'marketplace-promo-list',
  template: '#marketplace-promo-list-template'
  name: 'marketplace-promo-list'

  data: ->
    marketplace_featured: {}

  created: () ->
    this.getFeaturedMarketplace()
    return

  methods:

    updateFeaturedMarketplace: (data) ->
      this.marketplace_featured = data

    getFeaturedMarketplace: () ->
      _this = this
      $.ajax
        type: "GET"
        url: '/api/products?page=1&limit=3'
        success: (data) ->
          if data
            _this.updateFeaturedMarketplace data
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response

###
Fantasy promoboxes component
###

Vue.component 'fantasy-promo-list',
  template: '#fantasy-promo-list-template'
  name: 'fantasy-promo-list'

  data: ->
    fantasy_featured: {}

  created: () ->
    this.getFeaturedFantasy()
    return

  methods:

    updateFeaturedFantasy: (data) ->
      this.fantasy_featured = data

    getFeaturedFantasy: () ->
      _this = this
      $.ajax
        type: "GET"
        url: '/api/fantasy/*?page=1&pagesize=3&searchKeyword='
        success: (data) ->
          if data
            _this.updateFeaturedFantasy data
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
