"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true

###
Fantasy Listing Component
###

Vue.component 'fantasy-list',
  template: '#fantasy-list-template'
  props: ['gamecode']

  data: ->
    fantasies: {}
    sorry: false
    page: 1
    pagesize: 10
    last_page: 1
    searchKeyword: ''

  created: () ->
    this.getFantasies()
    return

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    updateFantasies: (data) ->
      this.fantasies = data.data
      this.page = data.current_page
      this.last_page = data.last_page
      Vue.nextTick () ->
        $('.c-timer').each ->
          $this = $(this)
          finalDate = moment($(this).data('countdown')).toDate()
          $this.countdown finalDate, (event) ->
            $this.html event.strftime('%D days %H hours %M min %S sec')
            return
          return

    getFantasies: ->
      _this = this
      $('.preloader').addClass 'visible'
      nanobar.go 20
      $.ajax
        type: "GET"
        url: '/api/fantasy/' + this.gamecode
        data:
          page: this.page
          pagesize: this.pagesize
          searchKeyword: this.searchKeyword
        success: (data) ->
          nanobar.go 100
          if data
            _this.updateFantasies data
          else
            #_this.showModal 'Failed to get friends'
          $('.preloader').removeClass 'visible'
        error: (response) ->
          nanobar.go 100
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          #_this.showModal 'Failed to get friends'
      return

    paginationStart: () ->
      if this.page > 3
        if this.page + 2 > this.last_page
          if this.last_page <= 4
            return 1
          else
            return this.last_page - 4
        else
          return this.page - 2
      else
        return 1

    paginationEnd: () ->
      pgend = this.paginationStart() + 4
      if pgend > this.last_page
        pgend = this.last_page
      return pgend

    pageNumbers: () ->
      start = this.paginationStart()
      end = this.paginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    getPage: (page) ->
      this.page = page
      this.getFantasies()

    search: () ->
      this.page = 1
      this.getFantasies()
      return

    onStatusChanged: () ->
      this.getPage(1)
      return

###
Fantasy Enter Component
###

Vue.component 'fantasy-enter',
  template: '#fantasy-enter-template'
  props: ['fid', 'salary', 'editing']

  data: ->
    fantasy: {}
    rules: []
    roster: []
    players: []
    matchups: []
    playerSelected: []
    slots: []
    selectedSlot: -1
    sorry: false
    remainingSalary: 0
    averageSalary: 0
    fantasyLoaded: false
    playersLoaded: false
    rosterLoaded: false
    page: 1
    pagesize: 10
    last_page: 1
    search_keyword: ''
    playerCount: 0

  created: () ->
    $('.preloader').addClass 'visible'
    this.getFantasy()
    this.getPlayers()
    this.updateSalary()
    if this.editing == 'yes'
      this.getRoster()
    else
      this.rosterLoaded = true
    return
    Vue.nextTick () ->
      $('.c-timer').each ->
        $this = $(this)
        finalDate = moment($(this).data('countdown')).add(1, 'days').toDate()
        $this.countdown finalDate, (event) ->
          $this.html event.strftime('%D days %H hours %M min %S sec')
          return
        return



  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    ordinalSuffix: (i) ->
      j = i % 10
      k = i % 100
      if j == 1 and k != 11
        return i + "st"
      if j == 2 and k != 12
        return i + "nd"
      if j == 3 and k != 13
        return i + "rd"
      return i + "th"

    initUI: ->
      # if this function is called due to pagination of players, don't refresh slots
      Vue.nextTick () ->
        $('.c-timer').each ->
          $this = $(this)
          finalDate = moment($(this).data('countdown')).add(1, 'days').toDate()
          $this.countdown finalDate, (event) ->
            $this.html event.strftime('%D days %H hours %M min %S sec')
            return
          return

      slotsCanBeUpdated = true
      for i, slot of this.slots
        if slot
          slotsCanBeUpdated = false
          break
      # update slots
      if slotsCanBeUpdated
        if this.fantasy.gamecode == 'lol'
          positions = [
            {position: 'top', label: 'TOP'},
            {position: 'jungler', label: 'JUNGLER'},
            {position: 'mid', label: 'MID'},
            {position: 'adc', label: 'ADC'},
            {position: 'support', label: 'SUPPORT'},
            {position: 'flex', label: 'FLEX'},
            {position: 'flex', label: 'FLEX'},
            {position: 'flex', label: 'FLEX'}
          ]
          this.slots = []
          for i, position of positions
            this.slots.push
              position: position.position
              label: position.label
              player: false
              captain: false
        else
          this.slots = []
          i = 0
          while i++ < 8
            this.slots.push
              position: ''
              label: 'Player ' + i
              player: false
              captain: false
      # load roster when editing
      if this.roster.players
        for i, player of this.roster.players
          player.id = player.hid
          this.slots[i].player = player
          if this.roster.captain_hid == player.id
            this.slots[i].captain = true
          for j, playerFromPool of this.players
            if player.id == playerFromPool.id
              this.playerSelected[j].value = true
              break

    updateSalary: ->
      remainingSalary = this.salary
      totalSalary = 0
      playerCount = 0
      for i, slot of this.slots
        if slot.player
          remainingSalary -= slot.player.salary
          totalSalary += slot.player.salary
          playerCount++
      this.remainingSalary = remainingSalary
      if playerCount > 0
        this.averageSalary = totalSalary / playerCount
      return

    getFantasy: ->
      _this = this
      _this.fantasyLoaded = false
      $.ajax
        type: "GET"
        url: '/fantasy/api/get'
        data:
          id: _this.fid
        success: (data) ->
          _this.fantasyLoaded = true
          if data
            _this.fantasy = data.fantasy
            _this.players = data.players
            _this.matchups = data.matchups
            if data.fantasy.rules and data.fantasy.rules.length > 0
              _this.rules = data.fantasy.rules
            else
              _this.rules = []
            if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
              _this.initUI()
          # loading indicator check
          if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
            $('.preloader').removeClass 'visible'
        error: (response) ->
          _this.fantasyLoaded = true
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          # loading indicator check
          if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
            $('.preloader').removeClass 'visible'
      return

    getRoster: ->
      _this = this
      _this.rosterLoaded = false
      $.ajax
        type: "GET"
        url: '/fantasy/api/roster'
        data:
          id: _this.fid
        success: (data) ->
          _this.rosterLoaded = true
          if data
            _this.roster = data.data
            if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
              _this.initUI()
          # loading indicator check
          if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
            $('.preloader').removeClass 'visible'
        error: (response) ->
          _this.fantasyLoaded = true
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          # loading indicator check
          if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
            $('.preloader').removeClass 'visible'
      return

    updatePlayersSelectedStatus: ->
      for i, player of this.players
        selected = false
        for j, slot of this.slots
          if slot.player and slot.player.id == player.id
            selected = true
            break
        this.playerSelected[i].value = selected
      return

    getPlayers: ->
      _this = this
      _this.playersLoaded = true
      $.ajax
        type: "GET"
        url: '/fantasy/api/players'
        data:
          id: _this.fid
          page: this.page
          limit: this.pagesize
          search: this.search_keyword
        success: (res) ->
          if res
            _this.page = res.current_page
            _this.last_page = res.last_page
            _this.players = res.data
            _this.playerCount = res.player_count
            # players selected status
            _this.playerSelected = []
            pc = _this.players.length
            i = 0
            while i < pc
              _this.playerSelected.push
                index: i
                value: false
              i++
            _this.updatePlayersSelectedStatus()
            if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
              _this.initUI()
          # loading indicator check
          _this.playersLoaded = true
          if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
            $('.preloader').removeClass 'visible'
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          # loading indicator check
          _this.playersLoaded = true
          if _this.fantasyLoaded and _this.playersLoaded and _this.rosterLoaded
            $('.preloader').removeClass 'visible'
      return

    paginationStart: () ->
      if this.page > 3
        if this.page + 2 > this.last_page
          if this.last_page <= 4
            return 1
          else
            return this.last_page - 4
        else
          return this.page - 2
      else
        return 1

    paginationEnd: () ->
      pgend = this.paginationStart() + 4
      if pgend > this.last_page
        pgend = this.last_page
      return pgend

    pageNumbers: () ->
      start = this.paginationStart()
      end = this.paginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    getPage: (page) ->
      this.page = page
      this.getPlayers()

    search: () ->
      this.page = 1
      this.getPlayers()
      return

    ###
    selectRosterPosition: (ev) ->
      $this = $(ev.target)
      $this.siblings().removeClass 'selected'
      if $this.hasClass('selected')
        $this.removeClass 'selected'
        this.selectedSlot = -1
      else
        $this.addClass 'selected'
        this.selectedSlot = $this.data 'index'
      return true
    ###

    addPlayer: (ev) ->
      $this = $(ev.target)
      index = $this.closest('tr').data 'index'
      player = this.players[index]
      if this.playerSelected[index].value
        return
      # get slot position
      assignedSlot = this.selectedSlot
      if assignedSlot == -1
        if this.fantasy.gamecode == 'lol'
          for i, slot of this.slots
            if (slot.position.toLowerCase() == player.position.toLowerCase() or slot.position.toLowerCase() == 'flex') and !slot.player
              assignedSlot = i
              break
        else
          for i, slot of this.slots
            if !slot.player
              assignedSlot = i
              break
      # still no empty slot - abort add player process
      if assignedSlot == -1
        return
      # some check for league of legends
      if this.fantasy.gamecode == 'lol'
        slotPosition = this.slots[assignedSlot].position.toLowerCase()
        if slotPosition != player.position.toLowerCase() and slotPosition != 'flex'
          this.showModal "Player position doesn't match."
          return
      selectedPlayerCountFromSameTeam = 0
      for i, slot of this.slots
        if slot.player.team_id == player.team_id
          selectedPlayerCountFromSameTeam++
      if selectedPlayerCountFromSameTeam > 2
        this.showModal "You can't add more than 3 players from the same team."
        return
      this.slots[assignedSlot].player = player #jQuery.extend true, {}, player
      this.playerSelected[index].value = true
      this.$set 'playerSelected', this.playerSelected
      this.updateSalary()
      return

    removePlayer: (index) ->
      playerAtRoster = this.slots[index].player
      for i, player of this.players
        if player.id == playerAtRoster.id
          this.playerSelected[i].value = false
          break
      this.$set 'playerSelected', this.playerSelected
      this.slots[index].player = false
      this.updateSalary()
      return

    removePlayerOnRoster: (ev) ->
      index = $(ev.target).closest('.roster-position').data 'index'
      this.removePlayer index
      return

    removePlayerOnList: (player) ->
      for i, slot of this.slots
        if slot.player and slot.player.id == player.id
          this.removePlayer i
          break
      return

    makeCaptain: (ev) ->
      index = parseInt($(ev.target).closest('.roster-position').data 'index')
      for i, slot of this.slots
        i = parseInt i
        if i == index
          this.slots[i].captain = true
        else
          this.slots[i].captain = false
      return

    enterContest: ->
      if this.remainingSalary < 0
        this.showModal 'The lineup is over the salary cap.'
        return
      players = []
      flex = []
      captainId = false
      for i, slot of this.slots
        if !slot.player
          this.showModal 'You must assign a player to every slot.'
          return false
        else
          if slot.position == 'flex'
            flex.push slot.player.id
          else
            players.push slot.player.id
          if slot.captain
            captainId = slot.player.id
      if !captainId
        this.showModal 'You must choose a player as a captain.'
        return

      # call api
      _this = this
      $('.ef-loader-bg').addClass 'visible'
      $.ajax
        type: "POST"
        url: '/fantasy/contest/entry'
        data:
          contest_id: _this.fantasy.cid
          captain_id: captainId
          player: players
          flex: flex
        success: (data) ->
          if data
            if data.result
              #_this.showModal 'Success!'
              window.location.href = '/fantasy/entries'
            else
              $('.ef-loader-bg').removeClass 'visible'
              error = data.error
              if error == 1
                _this.showModal 'Maxmium number of users have already entered the contest'
              else if error == 2
                _this.showModal 'You do not have enough coin to enter this contest'
              else
                _this.showModal 'Failed to enter fantasy contest'
          else
            $('.ef-loader-bg').removeClass 'visible'
            _this.showModal 'Failed to enter fantasy contest'
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          #_this.showModal 'Failed to get friends'
          $('.ef-loader-bg').removeClass 'visible'
      return

    editRoster: ->
      if this.remainingSalary < 0
        this.showModal 'The lineup is over the salary cap.'
        return
      players = []
      flex = []
      captainId = false
      for i, slot of this.slots
        if !slot.player
          this.showModal 'You must assign a player to every slot.'
          return false
        else
          if slot.position == 'flex'
            flex.push slot.player.id
          else
            players.push slot.player.id
          if slot.captain
            captainId = slot.player.id
      if !captainId
        this.showModal 'You must choose a player as a captain.'
        return

      # call api
      _this = this
      $('.ef-loader-bg').addClass 'visible'
      $.ajax
        type: "POST"
        url: '/fantasy/contest/entry'
        data:
          contest_id: _this.fantasy.cid
          captain_id: captainId
          player: players
          flex: flex
        success: (data) ->
          if data
            if data.result
              #_this.showModal 'Success!'
              window.location.href = '/fantasy/contest/' + _this.fid
            else
              $('.ef-loader-bg').removeClass 'visible'
              _this.showModal 'Failed to enter fantasy contest'
          else
            #_this.showModal 'Failed to get friends'
          $('.ef-loader-bg').removeClass 'visible'
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          #_this.showModal 'Failed to get friends'
          $('.ef-loader-bg').removeClass 'visible'
      return

Vue.component 'fantasy-entries',
  template: '#fantasy-entries-template'

  data: ->
    entries: []
    page: 1
    pagesize: 10
    last_page: 1

  computed:
    entry_created_at: ->
      if this.entries
        return moment($(this).data('date')).format('LLL')

  created: ->
    this.getContests()
    return

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    getContests: ->
      $('.preloader').addClass 'visible'
      _this = this
      $.ajax
        type: "GET"
        url: '/fantasy/api/entries'
        data:
          page: _this.page
          limit: _this.pagesize
        success: (data) ->
          if data
            _this.page = data.current_page
            _this.last_page = data.last_page
            _this.entries = data.data
          else
            #_this.showModal 'Failed to get friends'
          $('.preloader').removeClass 'visible'
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          #_this.showModal 'Failed to get friends'
          $('.preloader').removeClass 'visible'
      return

    paginationStart: () ->
      if this.page > 3
        if this.page + 2 > this.last_page
          if this.last_page <= 4
            return 1
          else
            return this.last_page - 4
        else
          return this.page - 2
      else
        return 1

    paginationEnd: () ->
      pgend = this.paginationStart() + 4
      if pgend > this.last_page
        pgend = this.last_page
      return pgend

    pageNumbers: () ->
      start = this.paginationStart()
      end = this.paginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    getPage: (page) ->
      this.page = page
      this.getContests()

Vue.component 'fantasy-contest',
  template: '#fantasy-contest-template'
  props: ['fid']

  data: ->
    fantasy: []
    rules: []
    roster: []
    matchups: []
    leaderboardPlayers: []
    lbpage: 1
    lbpagesize: 10
    lbLastPage: 1
    myRank: ''

  created: ->
    this.getFantasyContest()
    this.getLeaderboardPage()
    return

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    ordinalSuffix: (i) ->
      j = i % 10
      k = i % 100
      if j == 1 and k != 11
        return i + "st"
      if j == 2 and k != 12
        return i + "nd"
      if j == 3 and k != 13
        return i + "rd"
      return i + "th"

    getFantasyContest: ->
      _this = this
      $.ajax
        type: "GET"
        url: '/fantasy/api/getcontest'
        data:
          id: _this.fid
        success: (data) ->
          if data
            _this.roster = data.roster
            _this.fantasy = data.fantasy
            _this.matchups = data.matchups
            if data.fantasy.contest.rules and data.fantasy.contest.rules.length > 0
              _this.rules = data.fantasy.contest.rules
            else
              _this.rules = []

          else
            #_this.showModal 'Failed to get friends'
          $('.ef-loader-bg').removeClass 'visible'
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          #_this.showModal 'Failed to get friends'
          $('.ef-loader-bg').removeClass 'visible'
      return

    # leaderboard pagination
    lbPaginationStart: () ->
      if this.lbpage > 3
        if this.lbpage + 2 > this.lbLastPage
          if this.lbLastPage <= 4
            return 1
          else
            return this.lbLastPage - 4
        else
          return this.lbpage - 2
      else
        return 1

    lbPaginationEnd: () ->
      pgend = this.lbPaginationStart() + 4
      if pgend > this.lbLastPage
        pgend = this.lbLastPage
      return pgend

    lbPageNumbers: () ->
      start = this.lbPaginationStart()
      end = this.lbPaginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    lbGetPage: (page) ->
      this.lbpage = page
      this.getLeaderboardPage()

    getLeaderboardPage: ->
      _this = this
      $.ajax
        type: "GET"
        url: '/fantasy/api/leaderboard'
        data:
          id: _this.fid
          page: _this.lbpage
          limit: _this.lbpagesize
        success: (data) ->
          if data
            _this.lbpage = data.current_page
            _this.lbLastPage = data.last_page
            _this.leaderboardPlayers = data.data
            _this.myRank = data.my_rank
          else
            #_this.showModal 'Failed to get friends'
          #$('.ef-loader-bg').removeClass 'visible'
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          #$('.ef-loader-bg').removeClass 'visible'
      return

Vue.component 'fantasy-contest-scores',
  template: '#fantasy-contest-scores-template'
  props: ['fid']

  data: ->
    players: []
    statNames: ['Wins', 'Losses', '', '']
    lbpage: 1
    lbpagesize: 10
    lbLastPage: 1

  created: ->
    this.getPlayers()
    return

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    ordinalSuffix: (i) ->
      j = i % 10
      k = i % 100
      if j == 1 and k != 11
        return i + "st"
      if j == 2 and k != 12
        return i + "nd"
      if j == 3 and k != 13
        return i + "rd"
      return i + "th"

    # leaderboard pagination
    lbPaginationStart: () ->
      if this.lbpage > 3
        if this.lbpage + 2 > this.lbLastPage
          if this.lbLastPage <= 4
            return 1
          else
            return this.lbLastPage - 4
        else
          return this.lbpage - 2
      else
        return 1

    lbPaginationEnd: () ->
      pgend = this.lbPaginationStart() + 4
      if pgend > this.lbLastPage
        pgend = this.lbLastPage
      return pgend

    lbPageNumbers: () ->
      start = this.lbPaginationStart()
      end = this.lbPaginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    lbGetPage: (page) ->
      this.lbpage = page
      this.getPlayers()

    updateStatNames: (game) ->
      if game == 'csg'
        this.statNames = ['Kills', 'Deaths', 'Assists', 'Headshots']
      else if game == 'lol' or game == 'dota'
        this.statNames = ['Kills', 'Deaths', 'Assists', 'Creeps']
      else if game == 'hots'
        this.statNames = ['Kills', 'Deaths', '', '']
      else if game == 'sfv' or game == 'hrh'
        this.statNames = ['Wins', 'Losses', '', '']
      return

    getPlayers: ->
      _this = this
      $.ajax
        type: "GET"
        url: '/fantasy/api/stats'
        data:
          id: _this.fid
          page: _this.lbpage
          limit: _this.lbpagesize
        success: (data) ->
          if data
            _this.lbpage = data.current_page
            _this.lbLastPage = data.last_page
            _this.players = data.data
            _this.updateStatNames data.game
          else
            #_this.showModal 'Failed to get friends'
          #$('.ef-loader-bg').removeClass 'visible'
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
          #$('.ef-loader-bg').removeClass 'visible'
      return


###
Fantasy
###
if $('#fantasy').length > 0

  challenger = new Vue
    el: '#fantasy'

Vue.filter 'limit', (array, limit) ->
  array.slice 0, limit
