$ ->
  $ '#calculate'
  .click (e) ->
    rows = $('.well .row')
    current = 0
    prizepool = 0
    for row, i in rows
      position = $(row).find('input')[0].value
      prize = $(row).find('input')[1].value
      count = position - current
      current = position
      prizepool = prizepool + count * prize
    $('#result').text(prizepool + "$")
    return
  prize_pool_row = $ '#prize_pool_row'
  prize_pool_row.removeAttr "id"
  $('#add_prize_postion').data count: 0
  $ '#add_prize_postion'
  .click (e) ->
    $ '.well'
    .append prize_pool_row.clone()
    $('#add_prize_postion').data count: ($('#add_prize_postion').data "count")+1
    return
  return
