"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true

subscribeRoom = (subroom) ->
  console.log(subroom)
  window.room = broadcaster.subscribe(subroom)
  return

###
Challenger Participate Component
###

Vue.component 'challenger-participate',
  template: '#challenger-participate-template'
  props: ['cid', 'username']

  data: ->
    challengerEvent: {},
    loaded: false
    rounds: []
    currentRoundScore: ''
    inviteToggle: false
    firstLoad: true
    errors: false
    games: [
      'id_steam',
      'id_battlenet',
      'id_psn',
      'id_xblive:',
      'id_origin',
      'id_pvpnet',
      'id_nexon',
      'id_arenanet',
      'id_hires',
      'id_ios',
      'id_android'
    ]
    userToInvite: ''

  created: () ->
    this.getChallengerEvent()
    return

  computed:
    challengerHostId: ->
      if this.challengerEvent.creator.username
        gameid = this.challengerEvent.platform.toLowerCase()
        for i, game of this.games
          if game.indexOf(gameid) > -1
            return this.challengerEvent.creator[game]

    challengerOppId: ->
      if this.challengerEvent.opponent.username
        gameid = this.challengerEvent.platform.toLowerCase()
        for i, game of this.games
          if game.indexOf(gameid) > -1
            return this.challengerEvent.opponent[game]

    challengerDate: ->
      if this.challengerEvent.created_at
        return moment(this.challengerEvent.created_at).format('MMMM Do, h:mm:ss a')

  watch:
    'challengerEvent.id': ->
      if this.firstLoad == true
        this.firstLoad = false
        subscribeRoom(this.challengerEvent.id)
        this.watchSocket()
        return
      return

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    watchSocket: (e) ->
      _this = this;
      if window.room
        window.room.on 'update', (data) ->
          # If creator closes event before launch, notify the other users if that are on
          # the event page and redirect back to the challenger list, unless its the event
          # creator, who gets redirected after the post, and should not get notified
          if data.update == 'dismiss' && data.status == 'pending'
            if _this.username != data.proposed_dismiss
              confirm('Sorry, the creator has closed this event.')
              window.location.href = '/challenger'
          else
          _this.getChallengerEvent()
          console.log 'socket', data
          return

    updateChallengerEvent: (data) ->
      this.challengerEvent = data
      this.rounds = []
      _this = this
      this.$parent.$data.background = this.challengerEvent.background
      # load rounds data
      if this.challengerEvent.creator.username == this.username
        for i, round of this.challengerEvent.rounds
          if round.creator_score != null
            this.rounds[i] =
              score: round.creator_score
              locked: true
      else
        for i, round of this.challengerEvent.rounds
          if round.opponent_score != null
            this.rounds[i] =
              score: round.opponent_score
              locked: true
      return

    getChallengerEvent: ->
      _this = this
      nanobar.go 20
      $.ajax
        type: "GET"
        url: '/challenger/play?id=' + _this.cid
        success: (data) ->
          if data
            nanobar.go 100
            _this.updateChallengerEvent data
            _this.loaded = true
          else
            nanobar.go 100
            console.log 'something bad happened'
        error: (response) ->
          nanobar.go 100
          if response.status
            _this.errors = true

    inviteToggleOnOff: () ->
      this.inviteToggle = !this.inviteToggle
      return

    inviteUser: () ->
      if !this.userToInvite
        this.showModal 'Please choose a friend to invite.'
        return
      _this = this
      $.ajax
        type: "POST"
        url: '/challenger/invite'
        data:
          id: _this.challengerEvent.id
          username: _this.userToInvite
        success: (data) ->
          if data.result == 'success'
            _this.showModal 'User invited to this challenger event.'
          else
            _this.showModal data.result
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return

    cancelInvitation: () ->
      _this = this
      $.ajax
        type: "POST"
        url: '/challenger/cancelinvite'
        data:
          id: _this.challengerEvent.id
        success: (data) ->
          if data.result == 'success'
            _this.inviteToggle = false
            _this.showModal 'Invitation cancelled.'
          else
            _this.showModal data.result
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return

    enterChallengerEvent: () ->
      _this = this
      postData = {
        id: this.cid
      }
      $.ajax
        type: "POST"
        url: '/challenger/enter'
        data: postData
        success: (data) ->
          if data.result == 'success'
            console.log(data.result)
            console.log('Successfully Entered Arena')
            _this.getChallengerEvent()
            #$parent.enterTrek.splice(1);
          else
            console.log('Error:', data.result)
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return false

    dismissChallengerEvent: () ->
      # get a current status value before the ajax call
      eventStatus = this.challengerEvent.status
      confirmText = "Do you want to dismiss this event?"
      if eventStatus == 'active'
        confirmText = "Do you want to dismiss this event? Note that an active event can only be dismissed when both parties agree."
      if !confirm(confirmText)
        return
      $.ajax
        type: "POST"
        url: '/challenger/dismiss'
        data:
          id: this.cid
        success: (data) ->
          if data.result == 'success'
              if eventStatus != 'active'
                window.location.href = '/challenger'
          else
            console.log('Error:', data.result)
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return false

    agreeDismissChallengerEvent: () ->
      if !confirm("Do you agree to dismiss this event?")
        return
      $.ajax
        type: "POST"
        url: '/challenger/dismiss/agree'
        data:
          id: this.cid
        success: (data) ->
          if data.result == 'success'
            #window.location.href = '/challenger'
            window.location.href = '/challenger'
          else
            console.log('Error:', data.result)
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return false

    rejectDismissProposal: () ->
      if !confirm("Do you want to reject the proposal to dismiss?")
        return
      $.ajax
        type: "POST"
        url: '/challenger/dismiss/reject'
        data:
          id: this.cid
        success: (data) ->
          if data.result == 'success'
            # window.location.href = '/challenger'
          else
            console.log('Error:', data.result)
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return false

    cancelDismissProposal: () ->
      if !confirm("Do you want to cancel your proposal to dismiss?")
        return
      $.ajax
        type: "POST"
        url: '/challenger/dismiss/reject'
        data:
          id: this.cid
        success: (data) ->
          if data.result == 'success'
            # window.location.href = '/challenger'
          else
            console.log('Error:', data.result)
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return false

    scoreChallengerEvent: (round) ->
      postData = {
        id: this.cid
        round: round
        status: 0
        score: this.currentRoundScore
      }
      if postData.score == '' || postData.score == null || postData.score == '-'
        this.showModal 'Please select round result'
        return
      _this = this
      $.ajax
        type: "POST"
        url: '/challenger/match'
        data: postData
        success: (data) ->
          console.log data.result
          if data.result == 'success'
            _this.rounds.push
              score: _this.currentRoundScore
              locked: true
            _this.currentRoundScore = ''
            console.log(data.result)
            console.log('Successfully Scored Match')

          $('.ef-loader-bg').removeClass 'visible'
        error: (response) ->
          if response.responseJSON and response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response

    startChallengerEvent: (accept) ->
      postData = {
        id: this.cid
        accept: accept
      }
      $.ajax
        type: "POST"
        url: '/challenger/launch'
        data: postData
        success: (data) ->
          console.log data.result
          if data.result == 'success'
            console.log(data.result)
            console.log('Successfully Entered Arena')

          $('.ef-loader-bg').removeClass 'visible'
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response


      return false

    onSubmitUploadForm: () ->
      $('.ef-loader-bg').addClass 'visible'
      return true

    onFormSubmitResponse: () ->
      $('.ef-loader-bg').removeClass 'visible'
      responseData = $('#upload-iframe')[0].contentWindow.document.body.innerHTML
      if !responseData
        return
      responseData = responseData.replace /<pre\s*([a-z\-]+=\".*\")*>/g, ''
      responseData = responseData.replace '</pre>', ''
      try
        response = JSON.parse responseData
        if response.result == 'success'
          this.showModal 'Thank you, your image has uploaded.'
        if response.result != 'success'
          this.showModal 'Failed to upload file for dispute.'
      catch e
        console.log responseData
        this.showModal 'Server error occurred.'
      return true

    acceptDisputeResult: () ->
      _this = this;
      if !this.challengerEvent.disputes or this.challengerEvent.disputes.length == 0
        this.showModal 'No disputes found'
        return
      $('.ef-loader-bg').addClass 'visible'
      $.ajax
        type: "POST"
        url: '/challenger/dispute/update'
        data:
          id: this.challengerEvent.disputes[0].id
          accepted: true
        success: (data) ->
          if data.result == 'success'
            _this.showModal '<h3>Result Accepted</h3>Your acceptance has been recorded. The other player must also accept this judgement and then this arena will close. You job here is done! <br /><br /><a href="/challenger" class="btn btn-efantz">Play another game</a>'
            #window.location.reload()
          $('.ef-loader-bg').removeClass 'visible'
        error: (response) ->
          $('.ef-loader-bg').removeClass 'visible'
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return false



###
Challenger leaders component
###

Vue.component 'challenger-leaders-list',
  template: '#challenger-leaders-list-template'
  props: ['username', 'game']
  name: 'challenger-leaders-list'

  data: ->
    challenger_leaders: {}

  created: () ->
    this.getChallengerLeaders()
    return

  methods:

    updateChallengerLeaders: (data) ->
      this.challenger_leaders = data

    getChallengerLeaders: () ->
      _this = this
      $.ajax
        type: "GET"
        url: '/api/challenger/leaderboard?limit=6'
        success: (data) ->
          if data
            _this.updateChallengerLeaders data
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response

###
Challenger listing component
###

Vue.component 'challenger-list',
  template: '#challenger-list-template'
  props: ['username', 'game']

  data: ->
    challengers: {}
    sorry: false
    page: 1
    pagesize: 10
    firstLoad: true
    last_page: 1
    search_keyword: ''
    gamesFilter: ''
    platformFilter: ''

  created: () ->
    this.getChallengers()
    return

  computed:
    streamsFilterComp: ->
      if this.streamsFilter
        return '0'

  watch:
    'challengers': ->
      if this.firstLoad == true
        this.firstLoad = false
        subscribeRoom('challenger')
        this.watchSocket()
        return
      return

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    watchSocket: (e) ->
      _this = this;
      if window.room
        window.room.on 'update', (data) ->
          _this.getChallengers()
          console.log 'socket', data
          return

    updateChallengers: (data) ->
      console.log(data);
      this.challengers = data
      Vue.nextTick () ->
        $('.c-timer').each ->
          $this = $(this)
          finalDate = moment($(this).data('countdown')).add(1, 'days').toDate()
          $this.countdown finalDate, (event) ->
            $this.html event.strftime('%D days %H:%M:%S')
            return
          return

    getChallengers: (gamesFilter, platformFilter, statusFilter, streamsFilter, userFilter) ->
      _this = this
      nanobar.go 20
      $.ajax
        type: "GET"
        url: '/challenger/list'
        data:
          page: this.page
          limit: this.pagesize
          search: this.search_keyword
          game_id: this.game || gamesFilter
          platform: platformFilter
          status: statusFilter
          not_status: 'closed'
          streams: streamsFilter
          username: userFilter
        success: (data) ->
          if data
            nanobar.go 100
            _this.page = data.current_page
            _this.last_page = data.last_page
            _this.updateChallengers data.data
            $('.preloader').removeClass 'visible-o'
        error: (response) ->
          nanobar.go 100
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response

    paginationStart: () ->
      if this.page > 3
        if this.page + 2 > this.last_page
          if this.last_page <= 4
            return 1
          else
            return this.last_page - 4
        else
          return this.page - 2
      else
        return 1

    paginationEnd: () ->
      pgend = this.paginationStart() + 4
      if pgend > this.last_page
        pgend = this.last_page
      return pgend

    pageNumbers: () ->
      start = this.paginationStart()
      end = this.paginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    getPage: (page) ->
      this.page = page
      this.getChallengers()

    search: (keyword) ->
      this.search_keyword = keyword
      this.page = 1
      this.getChallengers()
      return

###
Challenger Listing Page (this will be a root instance, not component)
###
if $('#challenger-list-page').length > 0
  new Vue
    el: '#challenger-list-page'
    data:
      search_keyword: ''
      gamesFilter: ''
      platformFilter: ''
      statusFilter: ''
      streamsFilter: 'false'
      userFilter: ''

    computed:

      streamsFilterComp: ->
        if this.streamsFilter
          return '0'

      userFilterComp: ->
        if this.userFilter == false
          return ''
        if this.userFilter == true
          return true

    methods:
      onSearch: () ->
        this.$refs.challengerlist.search this.search_keyword
        return

      filter: () ->
        this.$refs.challengerlist.getChallengers this.gamesFilter, this.platformFilter, this.statusFilter, this.streamsFilterComp, this.userFilterComp


###
Challenger Entry Component
###

Vue.component 'challenger-entry',
  template: '#challenger-entry-template'

  data: ->
    challengers: {}
    step: 0
    game_image:''
    firstLoad: true
    games: {
      hots:'VlpNa5m1'
      csgo:'e7X4qkzl'
      hearth:'EZ5bopwm'
      dota2:'6WkPxkz1'
    }
    postData: {
      name: ''
      best_of: ''
      region: ''
      rules: ''
      time: ''
      game_id: ''
      platform: ''
      stream: ''
      entry_fee: ''
      fee_type: 'coin'
      start_at: ''
    }
    entry_fees: {
      gold: '0'
      cash: '0'
      gem: '0'
    }
    sorry: false


  computed:

    formattedEntry: ->
      if this.entry_fees.hasOwnProperty('gold')
        return parseInt(this.entry_fees.gold).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      else
        return '0'

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    selectGame: (e, game, gameImage) ->
      _this = this
      $('.selected').removeClass 'selected'
      $('.game').addClass('unselected')
      $(e.currentTarget).removeClass('unselected').addClass 'selected'
      _this.postData.platform = ''
      _this.postData.game_id = game
      _this.game_image = 'https://s3-us-west-2.amazonaws.com/efantz-site/games/raw/' + gameImage
      $('.select-platform').slideUp()
      $(e.currentTarget).next().slideDown()

    selectPlatform: (e, platform) ->
      _this = this
      _this.postData.platform = platform

    selectCurrency: (e, currency) ->
      _this = this
      if _this.postData.fee_type != currency
        #$('.selected').removeClass 'selected'
        #$(e.currentTarget).addClass 'selected'
        _this.postData.entry_fee = ''
        _this.entry_fees.gold = 0
        _this.entry_fees.gem = 0
        _this.entry_fees.cash = 0
        _this.postData.fee_type = currency

    prevStep: (e) ->
      _this = this
      $("html, body").animate({ scrollTop: 0 }, "slow")
      switch _this.step
        when 0
          _this.showModal '<h3>There\'s no going back cap\'n!</h3>You can\'t go back from here!'
        when 1
          $('.clg-create').removeClass 'hidden'
          $('.entry-fee').addClass 'hidden'
          _this.step--
        when 2
          $('.entry-fee').removeClass 'hidden'
          $('.match-rules').addClass 'hidden'
          _this.step--

    nextStep: (e) ->
      _this = this
      $("html, body").animate({ scrollTop: 0 }, "fast")
      switch _this.step
        when 0
          if _this.postData.game_id != '' && _this.postData.platform != ''
            _this.step++
            $('.clg-create').addClass 'hidden'
            $('.entry-fee').removeClass 'hidden'

            #Always Gold
            #$('.selected').removeClass 'selected'
            #$('#select-gold').addClass 'selected'
            _this.entry_fees.gold = 0
            _this.entry_fees.gem = 0
            _this.entry_fees.cash = 0
            _this.postData.entry_fee = ''
            _this.postData.fee_type = 'gold'

          else
            _this.step = 0
            _this.showModal '<h3>Warning</h3> Please Select a Game and Platform.'
        when 1
          this.postData.entry_fee = this.entry_fees[this.postData.fee_type]
          if _this.postData.entry_fee
            _this.step++
            $('.entry-fee').addClass 'hidden'
            $('.match-rules').removeClass 'hidden'
          else
            _this.step = 1
            _this.showModal '<h3>Warning</h3> You must input a wager.'

    createChallenger: (e) ->
      _this = this

      if _this.postData.name == ''
        _this.showModal 'Your Arena must have a name.'
        return
      if _this.postData.best_of == ''
        _this.showModal 'You must select a best of.'
        return

      nanobar.go 20
      $.ajax
        type: "POST"
        url: '/challenger/create'
        data: _this.postData
        success: (data) ->
          if data.result == 'success'
            console.log(data.result)
            console.log('Successfully created challenger event')
            window.location = '/challenger/enter/' + data.table.hid
          else
            console.log('Error')
          nanobar.go 100
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response
      return false

###
Challenger
###
if $('#challenger').length > 0
  challenger = new Vue
    el: '#challenger'
    data: ->
      background: ''
    watch: background:
      handler: () ->
        setTimeout (->
          $('.video-bg video').hide().load().fadeIn() #force redraw
          return
        ), 3000
        return
      deep: true
