"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true


###
leaderboard Feature Component
###

Vue.component 'leaderboards-feature',
  template: '#leaderboard-feature-template'
  props: ['lid']

  data: ->
    leaderboards: {},
    sorry: false

  created: () ->
    this.getLeaderboard()
    return

  methods:

    updateLeaderboard: (data) ->
      console.log(data);
      this.leaderboards = data
      #if Object.keys(this.currentEvent).length == 0
        #this.sorry = true;

    getLeaderboard: ->
      _this = this
      $('.preloader').addClass 'visible'
      $.ajax
        type: "GET"
        url: '/api/leaderboard/' + _this.lid
        success: (data) ->
          if data
            _this.updateLeaderboard data
          else
        error: (response) ->
          if response.responseJSON.result == 'error'
            alert response.responseJSON.error
          else
            console.log response


###
leaderboard Listing Component
###

Vue.component 'leaderboards-list',
  template: '#leaderboard-list-template'
  props: ['lid']

  data: ->
    leaderboards: {},
    sorry: false

  created: () ->
    this.getLeaderboard()
    return

  methods:

    updateLeaderboard: (data) ->
      console.log(data);
      this.leaderboards = data
      #if Object.keys(this.currentEvent).length == 0
        #this.sorry = true;

    getLeaderboard: ->
      _this = this
      $('.preloader').addClass 'visible'
      $.ajax
        type: "GET"
        url: '/api/leaderboard/' + _this.lid
        success: (data) ->
          if data
            _this.updateLeaderboard data
          else
        error: (response) ->
          if response.responseJSON.result == 'error'
            alert response.responseJSON.error
          else
            console.log response


###
Leaderboards
###
if $('#leaderboards').length > 0
  leaderboards = new Vue
    el: '#leaderboards'
