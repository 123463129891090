"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true

room = broadcaster.subscribe('live')

###
Modal
###

Vue.component 'modal',
  template: '#modal-template'
  props:
    getCurrentEventParent: {}

  data: ->
    possiblePayout: 0
    selectedRemark:
      amount: 1
    enterTrek: {}
    show: false
    success_image: '/img/contest-entered.gif'

  computed:
    maxAmount: ->
      return this.enterTrek.user.gem_balance

    possiblePayout: ->
      if this.enterTrek.wager_type == 'pot'
        return this.enterTrek.wager_payout.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      else if this.enterTrek.wager_type == 'wager'
        ratio = this.enterTrek.wager_payout.split(':')
        return (this.selectedRemark.amount * ratio[0]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    amountFormatted: ->
      return this.selectedRemark.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    maxAmountFormatted: ->
      return this.selectedRemark.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  events:
    'enter-trek': (enterTrek) ->
      this.enterTrek = enterTrek
      this.show = true
      return

  watch:
    show: ->
      #$('.modal-mask').height($(document).height());
      return

  created: () ->
    return

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    participateTrek: (e) ->
      _this = this
      console.log e.toElement
      #$('.preloader').addClass 'visible-o'
      if !_this.selectedRemark
        alert 'You must select an answer.'

      $.ajax
        type: "POST"
        url: '/gem/contest/entry/'
        data: _this.selectedRemark
        success: (data) ->
          if data.result == 200
            console.log(data.result)
            console.log('Successfully entered trek')
            $('.modal-container').addClass 'magictime swashOut'
            $('.notice-entered img').attr('src', _this.success_image)
            setTimeout (->
              $('.notice-entered').addClass 'visible-o'
              setTimeout (->
                _this.show = false
                $('.notice-entered').removeClass 'visible-o'
                $('.modal-container').toggleClass 'magictime swashOut'
              ), 2200
              return
            ), 800
            live.updateCurrentEvent()
            EFROOT.getCurrentUser()
          else
            console.log('Error')
        error: (response) ->
          console.log response
          _this.showModal '<h3>No Wager!</h3>You must click on a team / player / answer to select your wager.'
      return false

    selectRemark: (e, user_id, trek_id, remark_id, contest_id, amount, free) ->
      _this = this
      console.log e, user_id, trek_id, remark_id, contest_id, amount, free
      $('.selected').removeClass 'selected'
      $(e.currentTarget).addClass 'selected'
      _this.selectedRemark =
        user_id: user_id,
        trek_id: trek_id,
        remark_id: remark_id,
        contest_id: contest_id,
        amount: _this.selectedRemark.amount,
        free: free
      console.log(_this.selectedRemark)
      this.maxAmount = this.maxAmount - amount

###
Contests Component
###

Vue.component 'contests',
  template: '#contests-template'
  props: ['etype']

  data: ->
    contests: {}
    sorry: false
    page: 1
    pagesize: 5
    last_page: 1
    search_keyword: ''
    filter_game: ''
    subTreks: {}

  created: () ->
    this.getContest()
    return

  methods:

    filterGame: (id) ->
      this.etype = this.filter_game
      this.getContest()

    getInterior: (id) ->
      _this = this
      $.ajax
        type: "GET"
        url: '/api/contest/' + id
        success: (data) ->
          if data.events.treks
            _this.subTreks = data.events.treks
        error: (response) ->
          console.log 'Error: ' + response
      return false

    updateContests: (data) ->
      this.contests = data

      Vue.nextTick () ->
        $('.c-timer').each ->
          $this = $(this)
          finalDate = moment($(this).data('countdown')).toDate()
          $this.countdown finalDate, (event) ->
            totalHours = event.offset.totalDays * 24 + event.offset.hours
            $(this).html event.strftime(totalHours + ' hr %M min %S sec')
            #$this.html event.strftime('%D days %H hours %M min %S sec')
            return
          return

    getContest: ->
      _this = this
      #$('.preloader').addClass 'visible'
      dev = false  #DEV MODE = NO REDIS
      $('#loader').slideDown()
      if !dev
        featuredpath = 'contests'
      else
        featuredpath = 'contests'
      if _this.etype
        apiUrl = '/api/' + featuredpath + '/' + _this.etype
      else
        apiUrl = '/api/' + featuredpath + '/'

      $.ajax
        type: "GET"
        url: apiUrl
        data:
          page: this.page
          limit: this.pagesize
          search: this.search_keyword
        success: (data) ->
          if data
            _this.page = data.current_page
            _this.last_page = data.last_page
            _this.updateContests data.data
          else
            #_this.showModal 'Failed to get friends'
            #$('.preloader').removeClass 'visible-o'
          $('#loader').slideUp();
        error: (response) ->
          #$('.preloader').removeClass 'visible-o'
          #_this.showModal 'Failed to get friends'

    paginationStart: () ->
      if this.page > 3
        if this.page + 2 > this.last_page
          if this.last_page <= 4
            return 1
          else
            return this.last_page - 4
        else
          return this.page - 2
      else
        return 1

    paginationEnd: () ->
      pgend = this.paginationStart() + 4
      if pgend > this.last_page
        pgend = this.last_page
      return pgend

    pageNumbers: () ->
      start = this.paginationStart()
      end = this.paginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    getPage: (page) ->
      this.page = page
      this.getContest()

    onSearch: () ->
      this.page = 1
      this.getContest()
      return


###
Current Event Component
###

Vue.component 'current-event',
  template: '#current-event-template'
  props: ['etype', 'ehash']
  data: ->
    currentEvent: {},
    sorry: false,
    showModal: false,
    enterTrek: {},
    wager: '1',
    user: {},
    trayOpen: false,
    leeloo: {}

  events:
    'get-current-events': () ->
      this.getCurrentEvent(false)
      return

  created: () ->
    this.getCurrentEvent(false)
    this.watchSocket()
    this.getUser()
    return

  computed:
    date_past: ->
      if this.currentEvent.started_at
        return moment(this.currentEvent.started_at).format() < moment().format()

  methods:

    doDuty: (game) ->
      this.etype = game
      this.getCurrentEvent()
      return

    showModals: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    watchSocket: (e) ->
      _this = this;
      room.on 'update', (data) ->
        _this.getCurrentEvent(false)
        return

    # no longer in use
    goldTray: (e,data) ->
      if this.trayOpen
        this.trayOpen = false
        $(e.currentTarget).next().removeClass 'match-toggled'
        $(e.currentTarget).parent().find('.gold-event').removeClass 'toggled'
      else
        this.trayOpen = true
        $(e.currentTarget).next().addClass 'match-toggled'
        $(e.currentTarget).parent().find('.gold-event').addClass 'toggled'

    updateLive: (data) ->
      this.currentEvent = data
      this.$parent.$data.background = this.currentEvent.background
      this.$parent.$data.gamename = this.currentEvent.game
      this.$parent.$data.contestname = this.currentEvent.name
      if Object.keys(this.currentEvent).length == 0
        this.sorry = true;
      Vue.nextTick () ->
        $('.c-timer').each ->
          $this = $(this)
          finalDate = moment($(this).data('countdown')).toDate()
          $this.countdown finalDate, (event) ->
            totalHours = event.offset.totalDays * 24 + event.offset.hours
            $(this).html event.strftime(totalHours + ' hr %M min %S sec')
            return
          return

    getContest: ->
      _this = this
      #$('.preloader').addClass 'visible'
      $.ajax
        type: "GET"
        url: '/api/contests'
        success: (data) ->
          if data
            _this.updateLive data
          else
            console.log 'no data'
        error: (response) ->
          console.log response

    getCurrentEvent: (redis) ->
      nanobar.go 20
      _this = this
      apiUrl = ''
      dev = false  #DEV MODE = NO REDIS
      if _this.etype
        if redis && !dev
          featuredpath = 'featured2'
        else
          featuredpath = 'featured'
        apiUrl = '/api/' + featuredpath + '/' + _this.etype
      else if _this.ehash
        if redis && !dev
          featuredpath = 'contest'
        else
          featuredpath = 'contest'
        apiUrl = '/api/' + featuredpath + '/' + _this.ehash

      $.ajax
        type: "GET"
        url: apiUrl
        success: (data) ->
          if data
            _this.updateLive data
            nanobar.go 100
        error: (response) ->
          if response.responseJSON.result == 'error'
            alert response.responseJSON.error
            nanobar.go 100
          else
            console.log response

    openTrekDetails: (e, eid, tid) ->
      _this = this
      if _this.user.gem_balance > 0
        trekId = _this.currentEvent.events[eid].treks[tid];
        _this.enterTrek = _this.currentEvent.events[eid].treks[tid];
        _this.enterTrek.user = _this.user
        _this.enterTrek.currentEventId = _this.currentEvent.contest_id
        _this.enterTrek.wager = _this.wager
        live.updateTrekData(_this.enterTrek)
        return false
      else
        _this.showModals '<h3>No Gems!</h3>You must have gems to enter a wager! If you have no gems you can participate in challenger and tournaments to earn more for live!'

    openSubTrekDetails: (eid, mid, tid) ->
      _this = this
      if _this.user.gem_balance > 0
        _this = this
        trekId = _this.currentEvent.events[eid].treks[tid];
        _this.enterTrek = _this.currentEvent.events[eid].matches[mid].treks[tid];
        _this.enterTrek.user = _this.user
        _this.enterTrek.currentEventId = _this.currentEvent.contest_id
        _this.enterTrek.wager = _this.wager
        live.updateTrekData(_this.enterTrek)
        return false
      else
        alert 'You must have gems to enter a wager.'

    updateUser: (data) ->
      this.user = data
      if Object.keys(this.user).length == 0
        this.sorry = true;

    getUser: ->
      _this = this
      #$('.preloader').addClass 'visible'
      $.ajax
        type: "GET"
        url: '/api/user'
        success: (data) ->
          if data
            _this.updateUser data
          else
            console.log 'Failed to get user'
          #$('.preloader').removeClass 'visible-o'
        error: (response) ->
          if response.responseJSON.result == 'error'
            alert response.responseJSON.error
          else
            console.log response



###
DUTY Current Event Component
###

Vue.component 'duty-current-event',
  template: '#duty-current-event-template'
  props: ['ehash']
  data: ->
    currentEvent: {},
    sorry: false,
    showModal: false,
    etype: 0,
    enterTrek: {},
    wager: '1',
    user: {},
    trayOpen: false,
    leeloo: {}

  events:
    'get-current-eventsDU': (hash) ->
      this.ehash = hash
      console.log(hash)
      this.etype = null
      this.getCurrentEvent(hash)
      return

  created: () ->
    this.getCurrentEvent(null)
    this.watchSocket()
    this.getUser()
    return

  computed:
    date_past: ->
      if this.currentEvent.started_at
        return moment(this.currentEvent.started_at).format() < moment().format()

  methods:

    doDuty: (game) ->
      this.etype = game
      this.getCurrentEvent(null)
      return

    showModals: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    watchSocket: (e) ->
      _this = this;
      room.on 'update', (data) ->
        #_this.getCurrentEvent()
        return

    updateLive: (data) ->
      this.currentEvent = data
      this.$parent.$data.background = this.currentEvent.background
      this.$parent.$data.gamename = this.currentEvent.game
      this.$parent.$data.contestname = this.currentEvent.name
      if Object.keys(this.currentEvent).length == 0
        this.sorry = true;
      Vue.nextTick () ->
        $('.c-timer').each ->
          $this = $(this)
          finalDate = moment($(this).data('countdown')).toDate()
          $this.countdown finalDate, (event) ->
            totalHours = event.offset.totalDays * 24 + event.offset.hours
            $(this).html event.strftime(totalHours + ' hr %M min %S sec')
            return
          return

    getContest: ->
      _this = this
      #$('.preloader').addClass 'visible'
      $.ajax
        type: "GET"
        url: '/api/contests'
        success: (data) ->
          if data
            _this.updateLive data
          else
            console.log 'no data'
        error: (response) ->
          console.log response

    getCurrentEvent: (hash) ->
      nanobar.go 20
      _this = this
      apiUrl = ''
      dev = false  #DEV MODE = NO REDIS
      if _this.etype
        featuredpath = 'featured'
        apiUrl = '/api/' + featuredpath + '/' + _this.etype
      else if hash
        featuredpath = 'contest'
        apiUrl = '/api/' + featuredpath + '/' + hash



      console.log(apiUrl)
      console.log(_this.etype)
      console.log(hash)

      $.ajax
        type: "GET"
        url: apiUrl
        success: (data) ->
          if data
            _this.updateLive data
            nanobar.go 100
        error: (response) ->
          if response.responseJSON.result == 'error'
            alert response.responseJSON.error
            nanobar.go 100
          else
            console.log response

    openTrekDetails: (e, eid, tid) ->
      _this = this
      if _this.user.gem_balance > 0
        trekId = _this.currentEvent.events[eid].treks[tid];
        _this.enterTrek = _this.currentEvent.events[eid].treks[tid];
        _this.enterTrek.user = _this.user
        _this.enterTrek.currentEventId = _this.currentEvent.contest_id
        _this.enterTrek.wager = _this.wager
        live.updateTrekData(_this.enterTrek)
        return false
      else
        _this.showModals '<h3>No Gems!</h3>You must have gems to enter a wager! If you have no gems you can participate in challenger and tournaments to earn more for live!'

    openSubTrekDetails: (eid, mid, tid) ->
      _this = this
      if _this.user.gem_balance > 0
        _this = this
        trekId = _this.currentEvent.events[eid].treks[tid];
        _this.enterTrek = _this.currentEvent.events[eid].matches[mid].treks[tid];
        _this.enterTrek.user = _this.user
        _this.enterTrek.currentEventId = _this.currentEvent.contest_id
        _this.enterTrek.wager = _this.wager
        live.updateTrekData(_this.enterTrek)
        return false
      else
        alert 'You must have gems to enter a wager.'

    updateUser: (data) ->
      this.user = data
      if Object.keys(this.user).length == 0
        this.sorry = true;

    getUser: ->
      _this = this
      #$('.preloader').addClass 'visible'
      $.ajax
        type: "GET"
        url: '/api/user'
        success: (data) ->
          if data
            _this.updateUser data
          else
            console.log 'Failed to get user'
          #$('.preloader').removeClass 'visible-o'
        error: (response) ->
          if response.responseJSON.result == 'error'
            alert response.responseJSON.error
          else
            console.log response

###
DUTY Contests Component
###

Vue.component 'duty-contests',
  template: '#duty-contests-template'
  props: ['etype']

  data: ->
    contests: {}
    sorry: false
    page: 1
    pagesize: 5
    last_page: 1
    search_keyword: ''
    filter_game: ''
    subTreks: {}

  created: () ->
    this.getContest()
    return

  methods:

    filterGame: (id) ->
      this.etype = this.filter_game
      this.getContest()

    getInterior: (id) ->
      _this = this
      $.ajax
        type: "GET"
        url: '/api/contest/' + id
        success: (data) ->
          if data.events.treks
            _this.subTreks = data.events.treks
        error: (response) ->
          console.log 'Error: ' + response
      return false

    loadHash: (hash) ->
      live.updateCurrentEventDU(hash)

    updateContests: (data) ->
      this.contests = data
      Vue.nextTick () ->
        $('.c-timer').each ->
          $this = $(this)
          finalDate = moment($(this).data('countdown')).toDate()
          $this.countdown finalDate, (event) ->
            totalHours = event.offset.totalDays * 24 + event.offset.hours
            $(this).html event.strftime(totalHours + ' hr %M min %S sec')
            #$this.html event.strftime('%D days %H hours %M min %S sec')
            return
          return

    getContest: ->
      _this = this
      #$('.preloader').addClass 'visible'
      dev = false  #DEV MODE = NO REDIS
      $('#loader').slideDown()
      if !dev
        featuredpath = 'contests'
      else
        featuredpath = 'contests'
      if _this.etype
        apiUrl = '/api/' + featuredpath + '/' + _this.etype
      else
        apiUrl = '/api/' + featuredpath + '/'

      $.ajax
        type: "GET"
        url: apiUrl
        data:
          page: this.page
          limit: this.pagesize
          search: this.search_keyword
        success: (data) ->
          if data
            _this.page = data.current_page
            _this.last_page = data.last_page
            _this.updateContests data.data
          else
            #_this.showModal 'Failed to get friends'
            #$('.preloader').removeClass 'visible-o'
          $('#loader').slideUp();
        error: (response) ->
          #$('.preloader').removeClass 'visible-o'
          #_this.showModal 'Failed to get friends'

    paginationStart: () ->
      if this.page > 3
        if this.page + 2 > this.last_page
          if this.last_page <= 4
            return 1
          else
            return this.last_page - 4
        else
          return this.page - 2
      else
        return 1

    paginationEnd: () ->
      pgend = this.paginationStart() + 4
      if pgend > this.last_page
        pgend = this.last_page
      return pgend

    pageNumbers: () ->
      start = this.paginationStart()
      end = this.paginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

    getPage: (page) ->
      this.page = page
      this.getContest()

    onSearch: () ->
      this.page = 1
      this.getContest()
      return



###
live
###
if $('#live').length > 0
  live = new Vue
    el: '#live'
    data: ->
      background: ''
      gamename: ''
      contestname: ''

    events:
      'enter-trek': (data) ->
        this.$broadcast('enter-trek', data)
        return

      'get-current-events': () ->
        this.$broadcast('get-current-events')
        return

      'get-current-eventsDU': (hash) ->
        this.$broadcast('get-current-eventsDU', hash)
        return

    methods:
      updateTrekData: (data) ->
        this.$dispatch('enter-trek', data)

      updateCurrentEvent: () ->
        this.$dispatch('get-current-events')

      updateCurrentEventDU: (hash) ->
        this.$dispatch('get-current-eventsDU', hash)

    watch:
      background:
        handler: () ->
          setTimeout (->
            $('.video-bg video').hide().load().fadeIn() #force redraw
            return
          ), 3000
          return
        deep: true
