"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true


###
Steam Id -- SHOULD BE DONE ELSEWHERE
###

Vue.component 'steam-id',
	template: '#steam-id-template'
	name: 'steam-id'

	data: ->
		steam: {}

	created: () ->
		this.getId()
		return

	methods:

		updateSteamid: (data) ->
			this.steam = data

		getId: () ->
			_this = this
			$.ajax
				type: "GET"
				url: '/steam/playerSummary'
				success: (data) ->
					if data.steamid
						_this.updateSteamid data
				error: (response) ->
					if response.responseJSON.result == 'error'
						_this.showModal response.responseJSON.error
					else
						console.log response

###
My Profile Component
###

Vue.component 'my-profile',
	template: '#my-profile-template'

	data: ->
		user: {
			timezone: ''
		}
		sorry: false
		updated: false

	created: () ->
		this.getUser()
		return

	computed:

		profile_image: ->
			if this.user.profile_image
				return 'https://s3-us-west-2.amazonaws.com/efantz-site/' + this.user.profile_image

		background_image: ->
			if this.user.background_image
				bgImg = 'https://s3-us-west-2.amazonaws.com/efantz-site/' + this.user.background_image
				return 'background:url(' + bgImg + ') top center no-repeat; background-size: 100%;'

	methods:

		calculate_time_zone: ->
			this.user.timezone = moment.tz.guess()

		changeColor: (color) ->
			$('#save-color').slideDown()
			$('#main-stylesheet').attr('href', '/css/theme/' + color + '.css')
			this.user.color_ui = color
			this.saveProfile()
			return

		showModal: (message) ->
			$modal = $('#ef-modal')
			$modal.find('.modal-body').html message
			$modal.modal()
			return

		updateUser: (data) ->
			this.user = data
			if Object.keys(this.user).length == 0
				this.sorry = true;

		getUser: ->
			_this = this
			$('.preloader').addClass 'visible'
			$.ajax
				type: "GET"
				url: '/api/user'
				success: (data) ->
					if data
						_this.updateUser data
					else
						_this.showModal 'Failed to get user'
					$('.preloader').removeClass 'visible'
				error: (response) ->
					$('.preloader').removeClass 'visible'
					_this.showModal 'Failed to get user'

		saveProfile: () ->
			_this = this
			$('.ef-loader-bg').addClass 'visible'

			postData =
				email: _this.user.email
				description: _this.user.description
				primary: _this.user.primary
				secondary: _this.user.secondary
				state: _this.user.state
				city: _this.user.city
				zipcode: _this.user.zipcode
				country: _this.user.country
				email_alerts: if _this.user.email_alerts == true then 1 else 0
				desktop_alerts: if _this.user.desktop_alerts == true then 1 else 0
				favorite_game: _this.user.favorite_game
				id_steam: _this.user.id_steam
				id_battlenet: _this.user.id_battlenet
				id_psn: _this.user.id_psn
				id_xblive: _this.user.id_xblive
				id_origin: _this.user.id_origin
				id_pvpnet: _this.user.id_pvpnet
				id_nexon: _this.user.id_nexon
				id_arenanet: _this.user.id_arenanet
				id_hires: _this.user.id_hires
				id_ios: _this.user.id_ios
				id_android: _this.user.id_android
				stream_type: _this.user.stream_type
				stream: _this.user.stream
				color_ui: _this.user.color_ui
				private: if _this.user.private == true then 1 else 0
				timezone: _this.user.timezone

			$.ajax
				type: "POST"
				url: '/profile'
				data: $.param(postData)
				success: (data) ->
					if data.result
						console.log 'profile updated'
						$("html, body").animate({ scrollTop: 0 }, "slow")
						_this.updated = true
					else
						console.log 'update failed'
					$('.ef-loader-bg').removeClass 'visible'
				error: (response) ->
					$('.ef-loader-bg').removeClass 'visible'
			return false


###
Friends Component
###

Vue.component 'friends',
	template: '#friends-template'

	data: ->
		friends: {}
		sorry: false
		page: 1
		pagesize: 10
		last_page: 1
		search_keyword: ''

	created: () ->
		this.getFriends()
		return

	methods:

		showModal: (message) ->
			$modal = $('#ef-modal')
			$modal.find('.modal-body').html message
			$modal.modal()
			return

		updateFriendsList: (data) ->
			this.friends = data
			if Object.keys(this.friends).length == 0
				this.sorry = true;

		getFriends: ->
			_this = this
			$('.preloader').addClass 'visible'
			$.ajax
				type: "GET"
				url: '/friends'
				data:
					page: this.page
					limit: this.pagesize
					search: this.search_keyword
				success: (data) ->
					if data
						_this.page = data.current_page
						_this.last_page = data.last_page
						_this.updateFriendsList data.data
					else
						_this.showModal 'Failed to get friends'
					$('.preloader').removeClass 'visible'
				error: (response) ->
					$('.preloader').removeClass 'visible'
					_this.showModal 'Failed to get friends'

		deleteFriend: (fid) ->
			_this = this
			friendId = fid;
			$('.ef-loader-bg').addClass 'visible'
			$.ajax
				type: "POST"
				url: '/friends/delete/'
				data:
					id: friendId
				success: (data) ->
					if data.result
						_this.getFriends()
					else
						_this.showModal 'Failed to delete friend'
					$('.ef-loader-bg').removeClass 'visible'
				error: (response) ->
					$('.ef-loader-bg').removeClass 'visible'
			return false

		paginationStart: () ->
			if this.page > 3
				if this.page + 2 > this.last_page
					if this.last_page <= 4
						return 1
					else
						return this.last_page - 4
				else
					return this.page - 2
			else
				return 1

		paginationEnd: () ->
			pgend = this.paginationStart() + 4
			if pgend > this.last_page
				pgend = this.last_page
			return pgend

		pageNumbers: () ->
			start = this.paginationStart()
			end = this.paginationEnd()
			pgn = []
			i = start
			while i <= end
				pgn.push i
				i++
			return pgn

		getPage: (page) ->
			this.page = page
			this.getFriends()

		onSearch: () ->
			this.page = 1
			this.getFriends()
			return

###
Friends Requests Component
###

Vue.component 'friend-requests',
	template: '#friend-request-template'

	data: ->
		friendRequests: {},
		sorry: false

	created: () ->
		this.getFriendRequests()
		return

	methods:

		showModal: (message) ->
			$modal = $('#ef-modal')
			$modal.find('.modal-body').html message
			$modal.modal()
			return

		updateFriends: (data) ->
			this.friendRequests = data
			if this.friendRequests == {}
				this.sorry = true;

		getFriendRequests: ->
			_this = this
			nanobar.go 20
			$.ajax
				type: "GET"
				url: '/friends/received-requests'
				success: (data) ->
					nanobar.go 100
					if data
						_this.updateFriends data
					else
						_this.showModal 'Failed to get friends requests'
				error: (response) ->
					nanobar.go 100
					_this.showModal 'Failed to get friend requests'

		approveFriend: (fid) ->
			_this = this
			friendId = _this.friendRequests[fid].id;
			nanobar.go 20
			$.ajax
				type: "POST"
				url: '/friends/accept/'
				data:
					id: friendId
				success: (data) ->
					nanobar.go 100
					if data.result
						_this.friendRequests.splice(fid,1);
					else
						_this.showModal 'Failed to approve friend'
				error: (response) ->
					nanobar.go 100
					$('.ef-loader-bg').removeClass 'visible'
			return false

		rejectFriend: (fid) ->
			_this = this
			friendId = _this.friendRequests[fid].id;
			$('.ef-loader-bg').addClass 'visible'
			$.ajax
				type: "POST"
				url: '/friends/reject/'
				data:
					id: friendId
				success: (data) ->
					if data.result
						_this.friendRequests.splice(fid,1);
					else
						_this.showModal 'Failed to reject friend'
					$('.ef-loader-bg').removeClass 'visible'
				error: (response) ->
					$('.ef-loader-bg').removeClass 'visible'
			return false




###
Contests Entered Component
###

Vue.component 'contests-entered',
	template: '#contests-entered-template'

	data: ->
		contests: {},
		sorry: false

	created: () ->
		this.getContestsEntered()
		return

	methods:

		updateContests: (data) ->
			this.contests = data

		getContestsEntered: ->
			_this = this
			$('.preloader').addClass 'visible'
			$.ajax
				type: "GET"
				url: '/api/contests-entered'
				success: (data) ->
					if data
						_this.updateContests data
						console.log data
					else
						#_this.showModal 'Failed to get friends requests'
					$('.preloader').removeClass 'visible'
				error: (response) ->
					$('.preloader').removeClass 'visible'
					_this.showModal 'Failed to get contests'


###
Friends Requests
###
if $('#contests-entered').length > 0
	contestsEntered = new Vue
		el: '#contests-entered'

###
Friends Requests
###
if $('#friend-requests').length > 0
	friendRequests = new Vue
		el: '#friend-requests'

###
Friends
###
if $('#friends').length > 0
	friends = new Vue
		el: '#friends'


###
My Profile
###
if $('#my-profile').length > 0

	Vue.directive 'ef-dropzone',
		params: ['dropurl', 'dropname']
		bind: ->
			Dropzone.autoDiscover = false
			myDropzone = new Dropzone(@el, {
				url: this.params.dropurl
				maxFiles: 1
				maxFilesize: 200,
				uploadMultiple: false
				paramName: this.params.dropname
				init: ->
					console.log 'initialized'
					@on 'maxfilesexceeded', (file) ->
						@removeAllFiles()
						@addFile file
						return
					@on 'complete', (file) ->
						if @getUploadingFiles().length == 0 and @getQueuedFiles().length == 0
							console.log 'upload completed'
						return
			});
			return

	myProfile = new Vue
		el: '#my-profile'


###
Profile top submenu sublevel dropdown
###
$('.ef-top-submenu .dropdown-toggle').on 'click', (e) ->
	e.stopPropagation()
	$(this).closest('.nav-item.dropdown').toggleClass 'open'
	return false
