"use strict"

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true


_tournamentDataEmpty =
  eventName: ''
  eventUrl: ''
  eventType: 'singlestage'
  eventFormat: 'single'
  allowGuests: false
  includeMatchFor3rd: false
  rankBy: 'matchWins'
  groupStageFormat: 'single'
  participantsPerGroup: 4
  participantsAdvance: 2
  groupStageRankBy: 'matchWins'
  finalStageFormat: 'single'
  includeMatchFor3rdinFS: false
  finalStageRankBy: 'matchWins'
  eventRegistration: 'participantsList'
  signUpPagePublic: false
  eventRegOnly: false
  maxParticipants: false
  participantsNumber: 4
  startTime: ''
  timezone: '00:00'
  requireCheckin: false
  checkinTime: '1h'
  payoutType: 'none'
  fee: 10
  prizePositions: 3
  prizePositionValues: [
    { rank: 1, prize: 0 }
    { rank: 2, prize: 0 }
    { rank: 3, prize: 0 }
  ]
  fixedPrize: 0
  description: ''
  hideSeeds: false
  quickAdvance: false
  matchAttachments: false
  reportOwnScores: true
  shareAdminAccess: false
  shareAdmins: ''
  discourageIndexing: false
  tieBreak1: 'winsVsTied'
  tieBreak2: 'gameWins'
  tieBreak3: 'pointsScored'
  notifyUsersForMatch: true
  sendoutFinalResults: true

BracketsBase = Vue.extend

  methods:

    apiUrl: (apiPath) ->
      return '/bracketsapi/' + $('#tournamentUrl').val() + apiPath

    showModal: (message) ->
      $modal = $('#ef-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    calculate_time: (time) ->
      return moment(time).format('MMMM Do YYYY')

BracketsDashboardPage = BracketsBase.extend

  data: () ->
    init_data =
      page: 1
      pagesize: 10
      totalpages: 1
    return init_data

  methods:

    paginationStart: () ->
      if this.page > 3
        if this.page + 2 > this.totalpages
          if this.totalpages <= 4
            return 1
          else
            return this.totalpages - 4
        else
          return this.page - 2
      else
        return 1

    paginationEnd: () ->
      pgend = this.paginationStart() + 4
      if pgend > this.totalpages
        pgend = this.totalpages
      return pgend

    pageNumbers: () ->
      start = this.paginationStart()
      end = this.paginationEnd()
      pgn = []
      i = start
      while i <= end
        pgn.push i
        i++
      return pgn

# Brackets table for non-elimination tournaments
bracketsTableVms = []
$('.ef-brackets-table').each (index) ->
  bracketsTableVms.push new BracketsBase

    el: $(this)[0]

    data:
      table: []
      groupNumber: 1
      socketRoom2: false

    ready: () ->
      $(this.$el).closest('.ef-brackets-tab').attr 'data-index', index
      _groupNumber = $(this.$el).data 'group-number'
      if _groupNumber > 0
        this.groupNumber = _groupNumber
        this.tournamentId = $(this.$el).data 'tournament-id'
      this.getScores()
      this.watchSocket()

    methods:

      watchSocket: () ->
        if !this.socketRoom2
          this.socketRoom2 = broadcaster.subscribe("tournament" + this.tournamentId + "events")
          console.log 'socket room:' + "tournament" + this.tournamentId + "group" + this.groupNumber + "update"
        if this.socketRoom2
          console.log 'I AM NOW HERE'
          this.socketRoom2.on 'update', (data) =>
            console.log data
            this.getScores()
            return
        return

      setIndex: () ->
        return

      getScores: () ->
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/getmatchtable')
          data:
            group: this.groupNumber
          success: (data) ->
            if data.result
              _this.table = data.table
          error: (response) ->
            return

# Brackets drawing component for elimination tournaments
$('.ef-brackets-container').each () ->
  bracketsContainerVm = new BracketsBase

    el: $(this)[0]

    data:
      tournamentId: tournamentData.id
      groupNumber: 1
      groupFormat: ''
      matches: []
      playerCount: 0
      bracket_editable: false
      zoom: 1
      # UI measures
      playerGap: 0      # Must use same value with CSS
      matchGap: 30      # Must use same value with CSS
      roundGap: 100     # Must use same value with CSS
      playerWidth: 220  # Must use same value with CSS
      playerHeight: 30  # Must use same value with CSS
      isAdmin: false
      socketRoom: false

    ready: () ->
      this.isAdmin = isUserAdminOfThisTournament
      $(this.$el).find('.ef-zoom-slider').slider
        step: 0.1
        min: 0.5
        max: 2
        value: 1
        slide: (event, ui) =>
          this.zoom = ui.value
          this.setZoom()
      _groupNumber = $(this.$el).data 'group-number'
      if _groupNumber > 0
        this.groupNumber = _groupNumber
      this.groupFormat = $(this.$el).data 'group-format'

      this.getMatches()

      this.watchSocket()

      return

    methods:

      watchSocket: () ->
        if !this.socketRoom
          this.socketRoom = broadcaster.subscribe("tournament" + this.tournamentId + "events")
          console.log 'socket room:' + " tournament" + this.tournamentId + "group" + this.groupNumber + "update"
          if this.socketRoom
            console.log('I AM HERE')
            this.socketRoom.on 'update', (data) =>
              this.getMatches()
              console.log data
              return
        return

      fullscreen: () ->
        $('.ef-brackets-tab').toggleClass('fullscreen')
        return

      setZoom: () ->
        zoomWrapper = $(this.$el).find('.ef-brackets-zoom-wrapper')
        zoomWrapper.css 'transform', 'scale(' + this.zoom + ',' + this.zoom + ')'
        scaledHeight = zoomWrapper[0].getBoundingClientRect().bottom - zoomWrapper[0].getBoundingClientRect().top + 5
        zoomWrapper.closest('.ef-brackets-scroll-wrapper').height scaledHeight
        return

      getElement: () ->
        return $(this.$el)

      clearMatches: () ->
        this.getElement().find('.ef-brackets-area').children().remove()
        return

      getMatches: () ->
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/getmatches')
          data:
            group: this.groupNumber
          success: (data) ->
            if data.result
              _this.matches = data.matches
              _this.playerCount = data.player_count
              _this.bracket_editable = data.bracket_editable
              _this.clearMatches()
              _this.displayMatches()
          error: (response) ->
            # nanobar.go 100
            return

        return

      findMatch: (id) ->
        for match of this.matches
          if match.id == id
            return match
        return false

      getRoundMatches: (round) ->
        matches = []
        for index of this.matches
          match = this.matches[index]
          if match.match_round == round
            matches.push match
        return matches

      clonePlayer: (match, isPlayer1) ->
        $clone = this.getElement().find('.player-prototype').clone()
        $clone.removeClass 'player-prototype'
        $clone.attr 'data-match-id', match.id
        # player avatar
        playerAvatar = ''
        if isPlayer1
          playerAvatar = match.player1_avatar
        else
          playerAvatar = match.player2_avatar
        if playerAvatar
          $clone.find('.avatar').attr 'src', playerAvatar
        if isPlayer1
          $clone.attr 'data-adv-match', match.player1_advanced_match
          $clone.attr 'data-lost-match', match.player1_lost_match
          if match.player1_id
            seedStr = ''
            if match.player1_seed_number > 0
              $clone.addClass 'with-seed-number'
              seedStr = '<em>' + match.player1_seed_number + '</em>'
            $clone.find('.name').html seedStr + match.player1_name
            $clone.find('.avatar-img').attr 'src', (if match.player1_avatar then 'https://s3-us-west-2.amazonaws.com/efantz-site/' + match.player1_avatar else 'http://efantz-site.s3.amazonaws.com/profile/pf_default.jpg')
            $clone.attr 'data-id', match.player1_id
            $clone.addClass 'real-player'
          else if match.player1_lost_match_number > 0
            $clone.find('.name').html '<em>Loser of Match ' + match.player1_lost_match_number + '</em>'
        else
          $clone.attr 'data-adv-match', match.player2_advanced_match
          $clone.attr 'data-lost-match', match.player2_lost_match
          if match.player2_id
            seedStr = ''
            if match.player2_seed_number > 0
              $clone.addClass 'with-seed-number'
              seedStr = '<em>' + match.player2_seed_number + '</em>'
            $clone.find('.name').html seedStr + match.player2_name
            $clone.find('.avatar-img').attr 'src', (if match.player2_avatar then 'https://s3-us-west-2.amazonaws.com/efantz-site/' + match.player2_avatar else 'http://efantz-site.s3.amazonaws.com/profile/pf_default.jpg')
            $clone.attr 'data-id', match.player2_id
            $clone.addClass 'real-player'
          else if match.player2_lost_match_number > 0
            $clone.find('.name').html '<em>Loser of Match ' + match.player2_lost_match_number + '</em>'

        # if disqualified
        disqualified = parseInt(match.disqualified)
        if (match.disqualified == 1 and isPlayer1) or (match.disqualified == 2 and !isPlayer1)
          $clone.addClass 'disqualified'

        # checkin link
        checkedInStatus = if isPlayer1 then match.player1_checked_in else match.player2_checked_in
        checkInAllowed = false
        $checkInLink = $clone.find('.check-in-icon')
        playerId = if isPlayer1 then match.player1_id else match.player2_id
        if (isUserAdminOfThisTournament or tournamentData.userPlayerId == playerId) and (tournamentData.status == 1 or tournamentData.status == 3)
          checkInAllowed = true
        if checkInAllowed
          $checkInLink.addClass 'enabled'
        if !checkedInStatus
          if checkInAllowed
            _this = this
            $checkInLink.on 'click', ->
              $this = $(this)
              $.ajax
                type: "POST"
                url: _this.apiUrl('/matchcheckin')
                data:
                  matchId: match.id
                  playerId: playerId
                success: (data) ->
                  if data.result
                    $this.find('.fa').removeClass('fa-circle-o').addClass('fa-check-circle-o')
                  return
                error: (response) ->
                  return
              return false
        else
          $clone.find('.check-in-icon .fa').removeClass('fa-circle-o').addClass('fa-check-circle-o')

        return $clone

      twoDigits: (val) ->
        if val >= 10
          return val
        else
          return '0' + val

      timestampToDateString: (timestamp) ->
        date = new Date(timestamp * 1000)
        monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ]
        day = this.twoDigits(date.getDate())
        monthIndex = date.getMonth()
        year = date.getFullYear()
        hour = date.getHours()
        minute = this.twoDigits(date.getMinutes())
        apm = 'am'
        if hour >= 12
          hour -= 12
          apm = 'pm'
        hour = this.twoDigits(hour)
        return monthNames[monthIndex] + ' ' + day + ', ' + year + ' - ' + hour + ':' + minute + ' ' + apm

      cloneMatchButton: (match, x, y, $playersArea) ->
        $clone = this.getElement().find('.match-controls-container.prototype').clone()
        $clone.removeClass 'prototype'
        $clone.attr 'data-match-id', match.id
        $clone.css('left', x).css('top', y)
        if match.match_status == 10
          $clone.addClass 'played'
        if match.match_status == 4
          $clone.addClass 'disputed'
        lines_needed = false

        # Scores
        if match.match_status == 10 and match.scores and match.scores[0]
          $clone.find('.score.player1').html match.scores[0].player1_score
          $clone.find('.score.player2').html match.scores[0].player2_score
          if match.match_result == 1
            $clone.find('.score.player1').addClass 'winner'
          else if match.match_result == 2
            $clone.find('.score.player2').addClass 'winner'

        # Lines
        if match.player1_advanced_match > 0
          mx = this.getMatchPosX match.player1_advanced_match, $playersArea
          my = this.getMatchPosY match.player1_advanced_match, $playersArea
          if my > 0     # comparision should be with my because it's possible that mx is 0
            lines_needed = true
            if my < y
              lineClass = 'top'
              lineTop = my + this.playerHeight + this.playerGap / 2 - y - 1
            else
              lineClass = 'bottom'
              lineTop = this.playerHeight + this.playerGap / 2 - 1
            $clone
              .find('.line1')
              .addClass(lineClass)
              .css('left', mx + this.playerWidth - x)
              .css('top', lineTop)
              .css('width', x - mx - this.roundGap / 2 - this.playerWidth)
              .css('height', Math.abs(y - my) + this.playerGap / 2 + 2)
              .removeClass 'hidden'
        if match.player2_advanced_match > 0
          mx = this.getMatchPosX match.player2_advanced_match, $playersArea
          my = this.getMatchPosY match.player2_advanced_match, $playersArea
          if my > 0     # comparision should be with my because it's possible that mx is 0
            lines_needed = true
            if my < y
              lineClass = 'top'
              lineTop = my + this.playerHeight + this.playerGap / 2 - y - 1
            else
              lineClass = 'bottom'
              lineTop = this.playerHeight + this.playerGap / 2 - 1
            $clone
              .find('.line2')
              .addClass(lineClass)
              .css('left', mx + this.playerWidth - x)
              .css('top', lineTop)
              .css('width', x - mx - this.roundGap / 2 - this.playerWidth)
              .css('height', Math.abs(y - my) + this.playerGap / 2 + 2)
              .removeClass 'hidden'
        if lines_needed
          $clone.find('.line-into').removeClass 'hidden'

        # Match number
        $clone.find('.number').html match.match_number

        # Status
        if match.match_status == 3
          $clone.find('.status-button .play').css('display', 'none');
          $clone.find('.status-button .paused').css('display', 'inline-block');
        else
          $clone.find('.status-button .play').css('display', 'inline-block');
          $clone.find('.status-button .paused').css('display', 'none');

        _this = this
        bracketIndex = this.getElement().closest('.ef-brackets-tab').data 'index'
        matchId = $clone.data 'match-id'

        # match play/pause button click
        $clone.find('.status-button').on 'click', () ->
          $this = $(this)
          $.ajax
            type: "POST"
            url: _this.apiUrl('/togglematchstatus')
            data:
              id: matchId
            success: (data) ->
              if data.result
                if data.status == 3
                  $this.find('.play').css('display', 'none');
                  $this.find('.paused').css('display', 'inline-block');
                else
                  $this.find('.play').css('display', 'inline-block');
                  $this.find('.paused').css('display', 'none');
              return
            error: (response) ->
              return
          return false

        # bind match button click -> match dialog open event
        $clone.find('.submit-match').on 'click', () ->

          if indexVm
            indexVm.matchId = matchId
            indexVm.matchDlgComments = []

          # Show dialog for dummy match
          if !matchId
            if indexVm
              indexVm.winner = 0
              indexVm.matchDlgGroupFormat = _this.groupFormat
              indexVm.matchDlgBracketIndex = bracketIndex
              indexVm.submitAllowed = false
            $modal = $('#ef-match-dialog')
            $modal.modal()
            return false

          # Get match data for dialog
          nanobar.go 20
          $.ajax
            type: "POST"
            url: _this.apiUrl('/getmatch')
            data:
              id: matchId
            success: (data) ->
              if data.result
                nanobar.go 100
                match = data.match
                $modal = $('#ef-match-dialog')
                if indexVm
                  indexVm.winner = match.match_result
                  indexVm.match = match
                  indexVm.matchDlgGroupFormat = _this.groupFormat
                  indexVm.matchDlgBracketIndex = bracketIndex
                  indexVm.matchGroupArea = _this.getElement()
                  # Match time and place
                  if match.match_time
                    indexVm.time = _this.timestampToDateString(match.match_time)
                  else
                    indexVm.time = 'Please set a start time'
                  indexVm.startedEditingTP = false
                  # Match dialog submission enable/disable logic
                  indexVm.submitAllowed = false
                  indexVm.submissionPossible = false
                  indexVm.matchSubmitNotAllowedDueToNotCheckedIn = !(match.player1_checked_in and match.player2_checked_in)
                  # Already finished match - show result
                  if match.match_status == 10
                    indexVm.submissionPossible = true
                  # Group or normal stage submission
                  if tournamentData.status == 1 and _this.groupNumber < 9999
                    indexVm.submissionPossible = true
                    if match.player1_id > 0 and match.player2_id > 0
                      indexVm.submitAllowed = true
                  # Final stage submission
                  if tournamentData.status == 3 and _this.groupNumber == 9999
                    indexVm.submissionPossible = true
                    if match.player1_id > 0 and match.player2_id > 0
                      indexVm.submitAllowed = true
                  # score
                  if match.scores and match.scores[0]
                    score = match.scores[0]
                    indexVm.player1Score = score.player1_score
                    indexVm.player2Score = score.player2_score
                  else if match.temp_scores and match.temp_scores[0]
                    score = match.temp_scores[0]
                    indexVm.player1Score = score.player1_score
                    indexVm.player2Score = score.player2_score
                  else
                    indexVm.player1Score = 0
                    indexVm.player2Score = 0
                  # Admin data
                  if data.adminData
                    indexVm.adminData = data.adminData

                  indexVm.initMatchDialog()

                Vue.nextTick () ->
                  $modal.find('#matchdlg-match-id').val(matchId)
                  $modal.find('#matchdlg-player1-id').val(match.player1_id)
                  $modal.find('#matchdlg-player2-id').val(match.player2_id)
                  if match.player1_id > 0
                    $modal.find('.player1').html match.player1.name
                  else
                    $modal.find('.player1').html '---'
                  if match.player2_id > 0
                    $modal.find('.player2').html match.player2.name
                  else
                    $modal.find('.player2').html '---'
                  placeholderLogo = 'http://efantz-site.s3.amazonaws.com/profile/pf_default.jpg'
                  $modal.find('.player1-logo').attr 'src', if match.player1_image then 'http://efantz-site.s3.amazonaws.com/' + match.player1_image else placeholderLogo
                  $modal.find('.player2-logo').attr 'src', if match.player2_image then 'http://efantz-site.s3.amazonaws.com/' + match.player2_image else placeholderLogo
                  $modal.modal()
              else
                nanobar.go 100
                _this.showModal 'Failed to get match information'
            error: (response) ->
              nanobar.go 100
              _this.showModal 'Failed to get match information'

          # Get match discussion comments
          #_this.getMatchComments matchId
          indexVm.getMatchComments()

          return false

        # hide some controls when non-admin
        if !isUserAdminOfThisTournament
          $clone.find('.match-play-button').css 'display', 'none'
          $clone.find('.status-button').css 'display', 'none'
          $clone.find('.match-button').css 'right', '35px'

        return $clone

      getMatchPosX: (matchId, $playersArea) ->
        $player = $playersArea.find('.player[data-match-id=' + matchId + ']')
        if $player.length > 0
          return parseInt($player.css('left').replace('px', ''))
        else
          return 0

      getMatchPosY: (matchId, $playersArea) ->
        $player = $playersArea.find('.player[data-match-id=' + matchId + ']')
        if $player.length > 0
          return parseInt($player.css('top').replace('px', ''))
        else
          return 0

      checkIfMatchExists: ($playersArea, matchId) ->
        $player = $playersArea.find('.player[data-match-id=' + matchId + ']')
        if $player.length > 0
          return true
        else
          return false

      calculateByesCount: (pc) ->
        bye = pc
        bye -= 1
        bye |= bye >> 1
        bye |= bye >> 2
        bye |= bye >> 4
        bye |= bye >> 8
        bye |= bye >> 16
        bye += 1
        return bye - pc

      checkByeExists: (r1_match_count) ->
        while r1_match_count % 2 == 0
          r1_match_count = r1_match_count / 2
        if r1_match_count == 1
          return false
        else
          return true

      setPlayerInfo: ($player, id, matchId, advMatch, lostMatch, name, img) ->
        $player.attr 'data-id', id
        $player.attr 'data-match-id', matchId
        $player.attr 'data-adv-match', advMatch
        $player.attr 'data-lost-match', lostMatch
        $player.find('.name').html name
        $player.find('.avatar-img').attr('src', img)
        return

      onHoverPlayer: () ->
        return false

      onSwapPlayers: ($player1, $player2) ->
        # player1
        pid1 = $player1.attr 'data-id'
        mid1 = $player1.attr 'data-match-id'
        advMatch1 = $player1.attr 'data-adv-match'
        lostMatch1 = $player1.attr 'data-lost-match'
        name1 = $player1.find('.name').html()
        img1 = $player1.find('.avatar-img').attr('src')

        # player2
        pid2 = $player2.attr 'data-id'
        mid2 = $player2.attr 'data-match-id'
        advMatch2 = $player2.attr 'data-adv-match'
        lostMatch2 = $player2.attr 'data-lost-match'
        name2 = $player2.find('.name').html()
        img2 = $player2.find('.avatar-img').attr('src')

        # call api
        _this = this
        $.ajax
          type: "POST"
          url: _this.apiUrl('/swapplayers')
          data:
            match_id1: mid1
            player_id1: pid1
            match_id2: mid2
            player_id2: pid2
          success: (data) ->
            if data.result
              _this.setPlayerInfo $player1, pid2, mid1, advMatch1, lostMatch1, name2, img2
              _this.setPlayerInfo $player2, pid1, mid2, advMatch2, lostMatch2, name1, img1
            else
              _this.showModal 'Failed to change bracket.'
          error: (response) ->
            _this.showModal 'Failed to change bracket.'
            return
        return

      displayMatches: () ->
        # When there is only one player in the group
        if this.playerCount < 2 or !this.matches
          if this.groupNumber == 9999 and tournamentData.status < 2
            this.getElement().find('.ef-final-stage-not-ready-message').removeClass 'hidden'
          else
            this.getElement().find('.ef-no-matches-message').removeClass 'hidden'
          return

        # Show zoom control only when matches are available
        this.getElement().find('.ef-zoom-controls').removeClass 'hidden'

        # Display matches based on format
        switch this.groupFormat
          when 'single' then this.displayMatchesElimination()
          when 'double' then this.displayMatchesElimination()
          when 'multilevel' then this.displayMatchesElimination()
          when 'rr' then this.displayMatchesRoundRobin()
          when 'swiss' then this.displayMatchesSwiss()
          when 'accswiss' then this.displayMatchesSwiss()

        # Brackets management ui init
        if this.bracket_editable && isUserAdminOfThisTournament
          _this = this

          this.getElement().find('.player.real-player').draggable
            cursor: 'grabbing'
            helper: 'clone'
            #helper: (event) -> $ '<div class=\'ui-widget-header\'>I\'m a custom helper</div>'
            opacity: 1
            appendTo: 'body'
            containment: 'body'
            start: (event, ui) ->
              $(ui.helper).addClass("ui-draggable-helper");
              ui.position.left = 0
              ui.position.top = 0
            drag: (event, ui) ->
              changeLeft = ui.position.left - ui.originalPosition.left    # find change in left
              newLeft = ui.originalPosition.left + changeLeft / _this.zoom  # adjust new left by our zoomScale
              changeTop = ui.position.top - ui.originalPosition.top    # find change in top
              newTop = ui.originalPosition.top + changeTop / _this.zoom    # adjust new top by our zoomScale
              ui.position.left = newLeft
              ui.position.top = newTop

          this.getElement().find('.player.real-player').droppable
            hoverClass: 'hover-drop'
            drop: (event, ui) ->
              p1 = ui.draggable
              p2 = $(this)
              _this.onSwapPlayers ui.draggable, $(this)
        return

      displayEliminationMatchesLevel: ($playersArea, start_round, round_inc) ->
        $playersArea.html ''

        i = start_round   # Round number
        x = 30   # x coordinate of player cards

        # These will be needed for displaying virtual matches
        maxMatchId = 0
        maxPlayerId = 0
        prevRoundMatches = false

        height = 0

        # Draw brackets
        while !prevRoundMatches || prevRoundMatches.length > 0
          matches = this.getRoundMatches i
          y = this.matchGap

          # Check if bye exists in round 1
          bye_exists = false
          if i == 1 or i == -1
            #byes = this.checkByeExists(matches.length)
            r2_matches = this.getRoundMatches i + round_inc
            if matches and r2_matches
              r1_matches_count = matches.length
              r2_matches_count = r2_matches.length
              if r2_matches_count == r1_matches_count / 2
                bye_exists = false
              else
                bye_exists = true
            if bye_exists
              y += (this.matchGap / 2 + this.playerHeight + this.playerGap / 2)       # bye gap

          # Prepare to save matches in the round to array
          currentRoundMatches = new Array();

          # Display real matches in the round (may have only one player)
          len = matches.length
          j = 0
          while j < len
            match = matches[j]
            currentRoundMatches.push match

            # calculate match position
            if i < -1 or i > 1
              currentY = y
              matchHasOnlyOneLink = false
              parentY = 0
              parentChildGap = this.matchGap / 2 + this.playerHeight + this.playerGap / 2
              if match.player1_advanced_match > 0 and match.player2_advanced_match > 0 and this.checkIfMatchExists($playersArea, match.player1_advanced_match) and this.checkIfMatchExists($playersArea, match.player2_advanced_match)
                y = (this.getMatchPosY(match.player1_advanced_match, $playersArea) + this.getMatchPosY(match.player2_advanced_match, $playersArea)) / 2
              else if match.player1_advanced_match > 0 and this.checkIfMatchExists($playersArea, match.player1_advanced_match)
                parentY = this.getMatchPosY(match.player1_advanced_match, $playersArea)
                parentMatchId = match.player1_advanced_match
                y = parentY - parentChildGap
                matchHasOnlyOneLink = true
              else if match.player2_advanced_match > 0 and this.checkIfMatchExists($playersArea, match.player2_advanced_match)
                parentY = this.getMatchPosY(match.player2_advanced_match, $playersArea)
                parentMatchId = match.player2_advanced_match
                y = parentY - parentChildGap
                matchHasOnlyOneLink = true

              # avoid overlapping due to children dragging up
              if y < currentY + 10
                y = currentY

            mcx = x
            mcy = y

            # player 1 of the match
            $player1 = this.clonePlayer match, true
            $player1.css('left', x).css('top', y)
            $player1.addClass 'first'
            $playersArea.append $player1
            maxPlayerId = match.player1_id if match.player1_id > maxPlayerId

            y += (this.playerHeight + this.playerGap)

            #player 2 of the match
            $player2 = this.clonePlayer match, false
            $player2.css('left', x).css('top', y)
            $playersArea.append $player2
            maxPlayerId = match.player2_id if match.player2_id > maxPlayerId

            # Match dialog button
            $matchButton = this.cloneMatchButton match, mcx, mcy, $playersArea
            $playersArea.append $matchButton

            y += (this.playerHeight + this.matchGap)

            # Calculate max round id for virtual matches
            maxMatchId = match.id if match.id > maxMatchId

            j++ # while loop end

          # Calculate area height
          if y > height
            height = y

          # Prepare for next round
          prevRoundMatches = currentRoundMatches
          x += (this.playerWidth + this.roundGap)
          i += round_inc
          # -- while loop end --

        $playersArea.css('width', x).css('height', height)

        return

      displayMatchesElimination: () ->
        $playersArea = this.getElement().find('.ef-brackets-area.normal')
        this.displayEliminationMatchesLevel $playersArea, 1, 1
        if this.groupFormat == 'double'
          this.getElement().find('.ef-brackets-sep').removeClass 'hidden'
          $playersArea = this.getElement().find('.ef-brackets-area.losers-level')
          $playersArea.removeClass 'hidden'
          this.displayEliminationMatchesLevel $playersArea, -1, -1
        return

      displayMatchesRoundRobin: () ->
        i = 1
        $playersArea = this.getElement().find('.ef-brackets-area.normal')
        $playersArea.html ''
        y = 30
        width = 0
        while i < 1000
          round_matches = this.getRoundMatches i
          rmc = round_matches.length
          if rmc < 1
            break
          x = 30
          j = 0
          while j < rmc
            match = round_matches[j]

            # Player 1 of the match
            $player1 = this.clonePlayer match, true
            $player1.css('left', x).css('top', y)
            $player1.addClass 'first'
            $playersArea.append $player1

            # Player 2 of the match
            $player2 = this.clonePlayer match, false
            $player2.css('left', x).css('top', y + this.playerHeight + this.playerGap)
            $playersArea.append $player2

            # Match button
            $matchButton = this.cloneMatchButton match, x, y, $playersArea
            $playersArea.append $matchButton

            x += (this.playerWidth + this.roundGap)
            j++
          y += this.playerHeight * 2 + this.playerGap + this.matchGap * 1.5
          if x > width
            width = x
          i++
        $playersArea.css('width', width).css('height', y)

      displayMatchesSwiss: () ->
        swissRounds = $('#swiss-rounds').val()
        i = 1
        $playersArea = this.getElement().find('.ef-brackets-area.normal')
        $playersArea.html ''
        y = 30
        width = 0
        round_matches_size = 0
        dummyMatch = { id: 0, player1_id: 0, player2_id: 0, player1_advanced_match: 0, player2_advanced_match: 0, player1_lost_match: 0, player2_lost_match: 0 }
        while i <= swissRounds
          round_matches = this.getRoundMatches i
          rmc = round_matches.length
          x = 30
          if rmc > 0
            j = 0
            if round_matches_size == 0
              round_matches_size = rmc
            while j < rmc
              match = round_matches[j]

              # Player 1 of the match
              $player1 = this.clonePlayer match, true
              $player1.css('left', x).css('top', y)
              $player1.addClass 'first'
              $player1.attr 'data-round', i
              $playersArea.append $player1

              # Player 2 of the match
              $player2 = this.clonePlayer match, false
              $player2.css('left', x).css('top', y + this.playerHeight + this.playerGap)
              $player2.attr 'data-round', i
              $playersArea.append $player2

              # Match button
              match.match_number = ''
              $matchButton = this.cloneMatchButton match, x, y, $playersArea
              $matchButton.attr 'data-round', i
              $playersArea.append $matchButton

              x += (this.playerWidth + this.roundGap)
              j++
          else
            j = 0
            while j < round_matches_size
              # Player 1 of the match
              $player1 = this.clonePlayer dummyMatch, true
              $player1.css('left', x).css('top', y)
              $player1.addClass 'first'
              $player1.attr 'data-round', i
              $playersArea.append $player1

              # Player 2 of the match
              $player2 = this.clonePlayer dummyMatch, false
              $player2.css('left', x).css('top', y + this.playerHeight + this.playerGap)
              $player2.attr 'data-round', i
              $playersArea.append $player2

              # Match button
              dummyMatch.match_number = ''
              $matchButton = this.cloneMatchButton dummyMatch, x, y, $playersArea
              $matchButton.attr 'data-round', i
              $playersArea.append $matchButton

              x += (this.playerWidth + this.roundGap)
              j++
          y += this.playerHeight * 2 + this.playerGap + this.matchGap * 1.5
          if x > width
            width = x
          i++
        $playersArea.css('width', width).css('height', y)

# Dashboard -> Index, Group/Final Stage
if $('.brackets-page').length > 0
  indexVm = new BracketsDashboardPage

    el: '.brackets-page'

    data:
      tournamentId: tournamentData.id
      userId: tournamentData.userId
      swiss_rounds: tournamentData.swiss_rounds
      quickAdvance: tournamentData.quickAdvance
      startTime: tournamentData.startTime
      checkinTime: tournamentData.checkinTime.toLowerCase()
      startedEditingTP: false
      submitAllowed: false
      submissionPossible: false
      matchId: 0                    # match id for opened match dialog
      match: false
      matchDlgGroupFormat: ''
      matchDlgBracketIndex: 0
      matchDlgComments: []
      matchGroupArea: false
      matchSubmitNotAllowedDueToNotCheckedIn: false
      winner: 0
      player1Score: 0
      player2Score: 0
      tournamentStatus: 0
      time: ''
      checkingIn: false
      signingUp: false
      isAdmin: false
      tournament:
        fee: 0
        feeType: ''
      matchDlgDiscussionPage: 1
      matchDlgDiscussionPagesize: 5
      matchDlgDiscussionTotalPages: 1
      discussionMessage: ""
      matchDiscussionRoom: false
      matchDlgIsMatchPlayer: false
      adminData: false
      adminDataToUse:
        p1score: false
        p2score: false
      player1ScoreAdmin: 0
      player2ScoreAdmin: 0
      disqualifyingPlayer: ''
      refreshSocketRoom: false
      signupUsername: ''

    ready: () ->
      this.isAdmin = isUserAdminOfThisTournament
      this.tournamentStatus = tournamentData.status
      this.tournament.fee = tournamentFee
      this.tournament.feeType = tournamentFeeType
      this.startCheckInTimer()
      this.watchSocket()
      return

    methods:

      watchSocket: () ->
        # match discussion update
        if !this.matchDiscussionRoom
          this.matchDiscussionRoom = broadcaster.subscribe("tournament" + this.tournamentId + "events")
          console.log 'socket room:' + "tournament" + this.tournamentId + "-match-discussion"
          console.log tournamentData
        if this.matchDiscussionRoom
          this.matchDiscussionRoom.on 'update', (data) =>
            console.log 'matchDiscussionRoom'
            console.log data
            if data.match_id == this.matchId and data.user_id != this.userId
              this.updateMatchComments data
            return
        # tournament updates
        if !this.refreshSocketRoom
          this.refreshSocketRoom = broadcaster.subscribe("tournament" + this.tournamentId + "events")
          console.log 'socket room:' + "tournament" + this.tournamentId + "events"
        if this.refreshSocketRoom
          this.refreshSocketRoom.on 'forcerefresh', (data) =>
            window.location.reload()
            return
          this.refreshSocketRoom.on 'progressupdate', (data) =>
            if data.result
              this.updateProgress data.all_match_count, data.completed_match_count
            return
        return

      zeroPaddings: (hms) ->
        if hms < 10
          return '0' + hms.toString()
        return hms.toString()

      formattedStartDate: () ->
        if this.startTime == "0000-00-00 00:00:00"
          return false
        monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ]
        sdt = new Date(this.startTime)
        fdt = ''
        fdt += monthNames[sdt.getMonth()] + ' '
        fdt += sdt.getDate() + ', '
        fdt += sdt.getFullYear()
        return fdt

      formattedStartTime: () ->
        if this.startTime == "0000-00-00 00:00:00"
          return false
        sdt = new Date(this.startTime)
        fdt = ''
        fdt += this.zeroPaddings(sdt.getHours()) + ':'
        fdt += this.zeroPaddings(sdt.getMinutes()) + ':'
        fdt += this.zeroPaddings(sdt.getSeconds())
        return fdt

      initMatchDialog: () ->
        match = this.match
        if match.player1_id == tournamentData.userPlayerId or match.player2_id == tournamentData.userPlayerId
          this.matchDlgIsMatchPlayer = true
        if this.adminData
          this.adminDataToUse.p1score = []
          this.adminDataToUse.p2score = []
          if match.match_status == 10
            this.player1ScoreAdmin = match.scores[0].player1_score
            this.player2ScoreAdmin = match.scores[0].player2_score
          else
            this.player1ScoreAdmin = 0
            this.player2ScoreAdmin = 0
          this.adminDataToUse.p2score = 0
          if this.adminData.reportedScores
            for i, score of this.adminData.reportedScores
              if score.submitter_id == match.player1_id
                this.adminDataToUse.p1score = score
              else if score.submitter_id == match.player2_id
                this.adminDataToUse.p2score = score
          this.disqualifyingPlayer = match.disqualified
        if match.match_status == 10
          this.submitAllowed = false
        return

      onSignup: () ->
        nanobar.go 20
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/dosignup')
          data:
            username: this.signupUsername
          success: (data) ->
            nanobar.go 100
            _this.signingUp = false
            if data.result
              window.location.reload()
            else
              _this.showModal data.error
          error: (response) ->
            nanobar.go 100
            _this.signingUp = false
            _this.showModal 'Failed to sign up to the tournament'
        return false

      startCheckInTimer: () ->
        $checkInTimer = $ '.checkin-timer'
        if $checkInTimer.length > 0
          checkInStartTime = new Date(this.startTime)
          if this.checkinTime.search('d') > 0
            checkInStartTime.setDate checkInStartTime.getDate() - parseInt(this.checkinTime.replace('d', ''))
          else if this.checkinTime.search('h') > 0
            checkInStartTime.setHours checkInStartTime.getHours() - parseInt(this.checkinTime.replace('h', ''))
          else if this.checkinTime.search('m') > 0
            checkInStartTime.getMinutes checkInStartTime.getMinutes() - parseInt(this.checkinTime.replace('m', ''))
          $checkInTimer.countdown checkInStartTime, (event) ->
            $(this).html event.strftime('Check-in will open in <strong>%-d Days</strong> and <strong>%-H hours</strong>')
            return

      startCheckIn: () ->
        if this.tournament.fee > 0 and this.tournament.feeType and this.tournament.feeType != 'none'
          message = 'This action will check you in to this tournament. You will still need to check in to your individual matches, would you like to proceed?'
          $modal = $('#tournament-join-modal')
          $modal.find('.modal-body').html message
          $modal.modal()
        else
          this.onCheckIn()

      onCheckIn: () ->
        this.checkingIn = true
        nanobar.go 20
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/checkin')
          success: (data) ->
            nanobar.go 100
            _this.checkingIn = false
            if data.result
              window.location.reload()
            else
              _this.showModal 'Failed to check-in to the tournament'
          error: (response) ->
            nanobar.go 100
            _this.checkingIn = false
            _this.showModal 'Failed to check-in to the tournament'
        return false

      onUpdateSwissRound: () ->
        _this = this
        if this.swiss_rounds < 1
          this.showModal 'Please enter valid number for number of rounds'
          return
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/updateswissrounds')
          data:
            rounds: this.swiss_rounds
          success: (data) ->
            if data.result
              window.location.reload()
            else
              _this.showModal 'Failed to change the number of rounds'
              nanobar.go 100
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to change the number of rounds'
        return false

      startTournament: () ->
        nanobar.go 20
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/starttournament')
          success: (data) ->
            if data.result
              window.location.reload()
            else
              nanobar.go 100
              _this.showModal data.message
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to start tournament'
        return false

      endGroupStage: () ->
        nanobar.go 20
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/endgroupstage')
          success: (data) ->
            if data.result
              window.location.reload()
            else
              nanobar.go 100
              _this.showModal 'Failed to end the group stage'
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to end the group stage'
        return false

      startFinalStage: () ->
        nanobar.go 20
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/startfinalstage')
          success: (data) ->
            if data.result
              window.location.reload()
            else
              nanobar.go 100
              _this.showModal 'Failed to start the final stage'
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to start the final stage'
        return false

      endTournament: () ->
        nanobar.go 20
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/endtournament')
          success: (data) ->
            if data.result
              window.location.reload()
            else
              nanobar.go 100
              _this.showModal 'Failed to end the tournament'
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to end the tournament'
        return false

      # Below are match dialog methods
      onStartEditingTP: () ->
        this.startedEditingTP = true
        Vue.nextTick () ->
          $('#match-time').datetimepicker
            startDate: new Date()
            format: 'MM dd, yyyy - HH:ii p'
            showMeridian: true
        return

      onMatchSaveChanges: () ->
        matchId = $('#matchdlg-match-id').val()
        if !matchId
          this.showModal 'Cannot time for this match yet'
          return false
        if !this.time
          this.showModal 'Please enter time for this match'
          return false
        nanobar.go 20
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/setmatchtp')
          data:
            match_id: matchId
            time: new Date($('#match-time').data('datetimepicker').date) / 1000 | 0   # convert ms to sec
          success: (data) ->
            nanobar.go 100
            if !data.result
              _this.showModal 'Failed to save time and place for the match'
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to save time and place for the match'
        return false

      updateMatchComments: (data) ->
        this.matchDlgComments = data.comments
        this.matchDlgDiscussionPagesize = data.pagesize
        this.matchDlgDiscussionTotalPages = data.totalpages

      getMatchComments: () ->
        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: _this.apiUrl('/getmatchcomments')
          data:
            match_id: this.matchId
            page: this.matchDlgDiscussionPage
            pagesize: this.matchDlgDiscussionPagesize
          success: (data) ->
            nanobar.go 100
            if data.result
              _this.updateMatchComments data
          error: (response) ->
            nanobar.go 100
        return false

      getMatchCommentsPage: (page) ->
        this.matchDlgDiscussionPage = page
        this.getMatchComments()

      onMatchWriteDiscussionMessage: () ->
        matchId = $('#matchdlg-match-id').val()
        nanobar.go 20
        _this = this
        $.ajax
          type: "POST"
          url: this.apiUrl('/addmatchcomment')
          data:
            match_id: matchId
            text: this.discussionMessage
            pagesize: this.matchDlgDiscussionPagesize
          success: (data) ->
            nanobar.go 100
            _this.discussionMessage = ""
            _this.updateMatchComments data
          error: (response) ->
            nanobar.go 100
        return false

      matchDiscussionPaginationStart: () ->
        if this.matchDlgDiscussionPage > 3
          if this.matchDlgDiscussionPage + 2 > this.matchDlgDiscussionTotalPages
            if this.matchDlgDiscussionTotalPages <= 4
              return 1
            else
              return this.matchDlgDiscussionTotalPages - 4
          else
            return this.matchDlgDiscussionPage - 2
        else
          return 1

      matchDiscussionPaginationEnd: () ->
        pgend = this.matchDiscussionPaginationStart() + 4
        if pgend > this.matchDlgDiscussionTotalPages
          pgend = this.matchDlgDiscussionTotalPages
        return pgend

      matchDiscussionPageNumbers: () ->
        start = this.matchDiscussionPaginationStart()
        end = this.matchDiscussionPaginationEnd()
        pgn = []
        i = start
        while i <= end
          pgn.push i
          i++
        return pgn

      updateMatchWhenFinished: (matchId, p1score, p2score, data, disqualified) ->
        _this = this
        winnerId = data.winner_id
        loserId = data.loser_id

        # Refresh group matches table
        bracketsTableVms[_this.matchDlgBracketIndex].getScores()

        # Update related matches (win/lost match for elimination tournaments or next round generation for swiss/accswiss tournaments)
        if _this.matchDlgGroupFormat == 'single' or _this.matchDlgGroupFormat == 'double' or _this.matchDlgGroupFormat == 'multilevel'
          # Update advance match
          player = $('.player.real-player[data-id=' + winnerId + ']');
          advPlayer = $('.player[data-adv-match=' + matchId + ']:not(.real-player)');
          if advPlayer.length > 0
            advPlayer.attr 'data-id', winnerId
            advPlayer.find('.name').html player.find('.name').html()
            advPlayer.find('.avatar-img').attr 'src', player.find('.avatar-img').attr('src')
            if player.hasClass('with-seed-number')
              advPlayer.addClass 'with-seed-number'
          # Update lost match
          if !disqualified
            player = $('.player.real-player[data-id=' + loserId + ']');
            lostPlayer = $('.player[data-lost-match=' + matchId + ']');
            if lostPlayer.length > 0
              lostPlayer.attr 'data-id', loserId
              lostPlayer.find('.name').html player.find('.name').html()
              lostPlayer.find('.avatar-img').attr 'src', player.find('.avatar-img').attr('src')
              if player.hasClass('with-seed-number')
                lostPlayer.addClass 'with-seed-number'
        else
          # Update next round matches in swiss/accelerated swiss tournament
          if data.next_round and (_this.matchDlgGroupFormat == 'swiss' or _this.matchDlgGroupFormat == 'accswiss')
            nextRoundMatches = data.next_round
            nextRound = data.next_round[0].match_round
            firstPlayers = _this.matchGroupArea.find('.player.first[data-round=' + nextRound + ']')
            secondPlayers = _this.matchGroupArea.find('.player:not(.first)[data-round=' + nextRound + ']')
            matchContainers = _this.matchGroupArea.find('.match-controls-container[data-round=' + nextRound + ']')
            _tmc = _this.swiss_rounds * data.next_round.length
            firstPlayers.each (i, v) ->
              match = data.next_round[i]
              # player 1
              $player1 = $(firstPlayers[i])
              $player1.attr 'data-match-id', match.id
              $player1.attr 'data-id', match.player1_id
              $player1.find('.name').html match.player1.name
              # player 2
              $player2 = $(secondPlayers[i])
              $player2.attr 'data-match-id', match.id
              $player2.attr 'data-id', match.player2_id
              $player2.find('.name').html match.player2.name
              # match button container
              $matchContainer = $(matchContainers[i])
              $matchContainer.attr 'data-match-id', match.id

        # Update scores
        matchContainer = _this.matchGroupArea.find('.match-controls-container[data-match-id=' + matchId + ']')
        matchContainer.addClass 'played'
        matchContainer.find('.score.player1').html p1score
        matchContainer.find('.score.player2').html p2score
        if p1score > p2score
          matchContainer.find('.score.player1').addClass 'winner'
        else if p2score > p1score
          matchContainer.find('.score.player2').addClass 'winner'

        this.updateProgress data.all_match_count, data.completed_match_count
        return

      updateProgress: (totalMatchCount, completedMatchCount) ->
        percentage = 0
        if totalMatchCount > 0
          percentage = 100 * completedMatchCount / totalMatchCount
        $('#tournament-progress').attr('value', percentage).html(percentage + '%')
        if totalMatchCount == completedMatchCount
          $('.ef-progress').addClass 'hidden'
          $('.ef-end-stage').removeClass 'hidden'
        return

      onMatchSubmitScores: () ->
        matchId = $('#matchdlg-match-id').val()
        player1Id = $('#matchdlg-player1-id').val()
        player2Id = $('#matchdlg-player2-id').val()
        if !matchId or !player1Id or !player2Id
          this.showModal 'This match is not ready for submitting scores'
          return false
        p1score = this.player1Score
        p2score = this.player2Score

        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/submitmatchscores')
          data:
            matchId: matchId
            scores:
              player1: p1score
              player2: p2score
          success: (data) ->
            nanobar.go 100
            if data.result
              matchContainer = $('.match-controls-container[data-match-id=' + matchId + ']')
              if data.disputed
                matchContainer.addClass 'disputed'
              else if data.match_completed
                matchContainer.removeClass 'disputed'
                _this.updateMatchWhenFinished matchId, p1score, p2score, data
            else
              _this.showModal data.reason
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to submit match scores'
        return false

      onResolveMatch: () ->
        matchId = this.match.id
        player1Id = this.match.player1_id
        player2Id = this.match.player2_id
        if !matchId or !player1Id or !player2Id
          this.showModal 'This match is not ready for submitting scores'
          return false
        p1score = this.player1ScoreAdmin
        p2score = this.player2ScoreAdmin

        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/resolvematch')
          data:
            matchId: matchId
            scores:
              player1: p1score
              player2: p2score
          success: (data) ->
            nanobar.go 100
            if data.result
              matchContainer = $('.match-controls-container[data-match-id=' + matchId + ']')
              if data.disputed
                matchContainer.addClass 'disputed'
              else if data.match_completed
                matchContainer.removeClass 'disputed'
                _this.updateMatchWhenFinished matchId, p1score, p2score, data
            else
              _this.showModal data.reason
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to resolve match'
        return false

      onDisqualify: () ->
        matchId = this.match.id
        if !matchId
          this.showModal 'This match is not ready for submitting scores'
          return false
        player1Id = this.match.player1_id
        player2Id = this.match.player2_id
        disqualifiedPlayerId = 0
        p1s = 0
        p2s = 0
        disqualifyingPlayer = parseInt(this.disqualifyingPlayer)
        if disqualifyingPlayer == 1
          disqualifiedPlayerId = player1Id
          p2s = 1
        else
          disqualifiedPlayerId = player2Id
          p1s = 1
        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/disqualifyplayer')
          data:
            matchId: matchId
            disqualified: this.disqualifyingPlayer
          success: (data) ->
            nanobar.go 100
            if data.result
              player = $('.player[data-id=' + disqualifiedPlayerId + ']')
              player.addClass 'disqualified'
              _this.updateMatchWhenFinished matchId, p1s, p2s, data, disqualifyingPlayer
            else
              _this.showModal data.reason
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to disqualify a player'
        return false

      shuffleSeeds: () ->
        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/shuffle')
          success: (data) ->
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
        return false

# Select Game -> Create Event, Dashboard -> Settings page
if $('#settings-content').length > 0

  settingsVm = new BracketsDashboardPage

    el: '#settings-content'

    data:

      eventUrl: 'event.example.com'
      rankBy: [
        { key: 'matchWins', value: 'Match wins', tieBreak1: 'winsVsTied', tieBreak2: 'gameWins', tieBreak3: 'pointsScored' }
        { key: 'gameWins', value: 'Game/Set wins', tieBreak1: 'winsVsTied', tieBreak2: 'matchWins', tieBreak3: 'pointsScored' }
        { key: 'gameWinPer', value: 'Game/Set win %', tieBreak1: 'winsVsTied', tieBreak2: 'matchWins', tieBreak3: 'pointsScored' }
        { key: 'pointsScored', value: 'Points Scored', tieBreak1: 'winsVsTied', tieBreak2: 'pointsDiff', tieBreak3: 'matchWins' }
        { key: 'pointsDiff', value: 'Points Difference', tieBreak1: 'winsVsTied', tieBreak2: 'pointsScored', tieBreak3: 'matchWins' }
        { key: 'customPoints', value: 'Custom (points system)', tieBreak1: 'winsVsTied', tieBreak2: 'matchWins', tieBreak3: 'pointsDiff' }
      ]
      formData: _tournamentDataEmpty
      game: 'dota'
      rankByText: 'Match Wins'
      urlNotAllowed: false
      submitAllowed: true
      urlMsgVisible: false
      originalUrl: ''
      majorSum: 0
      settingsDisabled: false
      prizeAlertClass: 'alert-efantz'

    ready: () ->

      $('.datetime-picker').datetimepicker
        format: 'MM dd, yyyy - HH:ii p'
        showMeridian: true

      $('.expand-transition').each (index, obj)->
        $obj = $(obj)
        height = parseInt($obj.css('height').replace('px', '')) + 50
        $obj.find('.height-changer').each () ->
          height += 90    # approximate height for height changer div
        $obj.css 'max-height', height
        return true

      if typeof tournamentData != 'undefined' and tournamentData
        this.formData = tournamentData
        if this.formData.status != 0
          this.settingsDisabled = true
      else
        now = new Date()
        jan = new Date now.getFullYear(), 0, 1
        jul = new Date now.getFullYear(), 6, 1
        timezoneOffset = Math.max jan.getTimezoneOffset(), jul.getTimezoneOffset()
        timezoneOffset = -timezoneOffset
        for i, tz of timezoneData
          if tz.offset == timezoneOffset
            this.formData.timezone = tz.key
            break

      this.originalUrl = this.formData.eventUrl

      return

    methods:

      ordinalSuffix: (i) ->
        j = i % 10
        k = i % 100
        if j == 1 and k != 11
          return "st"
        if j == 2 and k != 12
          return "nd"
        if j == 3 and k != 13
          return "rd"
        return "th"

      calculate_time_zone: ->
        this.formData.timezone = moment.tz.guess()

      onUrlChange: () ->
        this.urlNotAllowed = false
        this.submitAllowed = false
        this.urlMsgVisible = false
        _this = this
        $.ajax
          type: "POST"
          url: '/bracketsapi/checkurl'
          data:
            url: this.formData.eventUrl
          success: (data) ->
            if !data.allowed && _this.originalUrl != _this.formData.eventUrl
              _this.urlNotAllowed = true
            else
              _this.urlNotAllowed = false
              _this.submitAllowed = true
            _this.urlMsgVisible = true
          error: (response) ->
            return

      onPrizePositionCountChange: (e) ->
        if this.formData.prizePositionValues.length > this.formData.prizePositions
          while this.formData.prizePositionValues.length > this.formData.prizePositions
            this.formData.prizePositionValues.pop()
        else if this.formData.prizePositionValues.length < this.formData.prizePositions
          rank = this.formData.prizePositionValues.length + 1
          while this.formData.prizePositionValues.length < this.formData.prizePositions
            this.formData.prizePositionValues.push
              rank: rank
              prize: 0
            rank++
        Vue.nextTick ->
          $container = $(e.target).closest '.prize-positions-container'
          $container.css 'max-height', $container.children('.prize-positions-inner-wrapper').height() + 30
        return false

      onPrizePositionUpdate: () ->
        sum = 0
        this.formData.prizePositionValues.forEach (pos) ->
          sum += parseInt(pos.prize)
        this.majorSum = sum
        if sum == 100
          this.prizeAlertClass = 'alert-efantz'
          return true
        else
          this.prizeAlertClass = 'alert-efantz-loud'
          return false

      checkIfPowerOf2: (n) ->
        while n % 2 == 0
          n /= 2
        if n > 1
          return false
        return true

      onCheckSubmit: () ->

        # tournament name empty check
        if !this.formData.eventName
          this.showModal 'Please enter the tournament name.'
          return false

        # tournament url empty check
        if !this.formData.eventUrl
          this.showModal 'Please enter the tournament url.'
          return false

        # tournament url duplication check
        if this.urlNotAllowed
          this.showModal 'Current URL is not available.'
          return false

        # participants advance check
        if this.formData.eventType == 'twostage' and not this.checkIfPowerOf2(this.formData.participantsAdvance)
          this.showModal 'Number of participants advancing from each group should be the power of 2.'
          return false
        if this.formData.maxParticipants and this.formData.participantsNumber < 1
          this.showModal 'Invalid participants number in tournament.'
          return false

        if this.formData.payoutType != 'none'
          # prize position count check
          if this.formData.maxParticipants and parseInt(this.formData.prizePositions) > parseInt(this.formData.participantsNumber)
            this.showModal 'Number of prize positions cannot be greater than number of maximum players.'
            return false

          # prize values and order check
          sum = 0
          prev_prize = -1
          for k, pos of this.formData.prizePositionValues
            sum += parseInt(pos.prize)
            pz = parseInt(pos.prize)
            if prev_prize >= 0 and prev_prize < pz
              this.showModal 'Higher rank should have equal or higher prize.'
              return false
            prev_prize = pz
          if sum != 100
            this.showModal 'Prize values must add up to 100%'
            return false

          # tournament format check
          if this.formData.type == 'singlestage' and (this.formData.eventFormat == 'single' or this.formData.eventFormat == 'double')
            if (this.formData.includeMatchFor3rd and this.formData.prizePositions > 3) or (!this.formData.includeMatchFor3rd and this.formData.prizePositions > 2)
              this.showModal 'In single/double elimination tournaments, prize position count cannot be bigger than 2(or 3 if match for 3rd is included).'
              return false
          if this.formData.type == 'twostage' and (this.formData.finalStageFormat == 'single' or this.formData.finalStageFormat == 'double')
            if (this.formData.includeMatchFor3rdinFS and this.formData.prizePositions > 3) or (!this.formData.includeMatchFor3rdinFS and this.formData.prizePositions > 2)
              this.showModal 'In single/double elimination tournaments, prize position count cannot be bigger than 2(or 3 if match for 3rd is included in final stage).'
              return false

        # some pre-submit processes
        if this.formData.payoutType == 'none'
          this.formData.prizePositions = 0
          this.formData.prizePositionValues = []

        # tournament start time/timezone check
        if !this.formData.startTime
          this.showModal 'Please set the tournament start time.'
          return false
        if !this.formData.timezone
          this.showModal 'Please set the timezone for the tournament start time.'
          return false
        return true

      onCreateFormSubmit: (event) ->
        if not this.onCheckSubmit()
          return false
        this.submitAllowed = false
        nanobar.go 20
        _this = this
        Vue.nextTick ->
          $.ajax
            type: "POST"
            url: '/bracketsapi/create'
            data: new FormData($('#tournament-create-form')[0])
            dataType: 'json'
            processData: false
            contentType: false
            success: (data) ->
              _this.submitAllowed = true
              nanobar.go 100
              if data.result
                window.location.href = '/brackets/' + _this.formData.eventUrl
              else
                _this.showModal data.error
            error: (response) ->
              _this.submitAllowed = true
              nanobar.go 100
              _this.showModal 'Failed to create the tournament.'
          return
        return false

      onUpdateFormSubmit: (event) ->
        if not this.onCheckSubmit()
          return false
        this.submitAllowed = false
        nanobar.go 20
        _this = this
        Vue.nextTick ->
          $.ajax
            type: "POST"
            url: _this.apiUrl('/update')
            data: new FormData($('#tournament-update-form')[0])
            dataType: 'json'
            processData: false
            contentType: false
            success: (data) ->
              _this.submitAllowed = true
              nanobar.go 100
              if data.result
                _this.showModal 'Settings saved successfully.'
              else
                _this.showModal data.error
            error: (response) ->
              _this.submitAllowed = true
              nanobar.go 100
              _this.showModal 'Failed to save settings.'
          return
        return false

      selectGame: (e, game) ->
        $('.selected').removeClass 'selected'
        $('.game').addClass('unselected')
        $(e.currentTarget).removeClass('unselected').addClass 'selected'
        this.game = game

      onBeginCreation: (event) ->
        $form = $(event.target)
        if this.game
          window.location = $form.attr('action') + '/' + this.game
        return false

      randomEventUrl: (event) ->
        s = ''
        loop
          r = Math.random()
          if r < 0.1
            c = Math.floor(r * 100)
          else
            if r > 0.5
              c = 97
            else
              c = 65
            c = String.fromCharCode(Math.floor(r * 26) + c)
          s += c
          break if s.length >= 10
        this.formData.eventUrl = s
        this.onUrlChange()
        return

      onChangeRankBy: (rankByValue) ->
        for rankby in this.rankBy
          if rankby.key == rankByValue
            this.rankByText = rankby.value
            this.formData.tieBreak1 = rankby.tieBreak1
            this.formData.tieBreak2 = rankby.tieBreak2
            this.formData.tieBreak3 = rankby.tieBreak3

# Dashboard -> Discussion
if $('#discussion-content').length > 0
  discussionVm = new BracketsDashboardPage

    el: '#discussion-content'

    data:

      tournamentId: tournamentData.id
      userId: tournamentData.userId
      newcomment: ''
      username: $('#username').val()
      comments: []
      discussionRoom: false

    ready: () ->
      this.getComments 1
      this.watchSocket()

      return

    methods:

      moments: (date) ->
        return moment(date).format('MMMM Do YYYY, h:mm:ss a')

      watchSocket: () ->
        if !this.discussionRoom
          this.discussionRoom = broadcaster.subscribe("tournament" + this.tournamentId + "discussion")
          console.log 'socket room:' + "tournament" + this.tournamentId + "discussion"
        if this.discussionRoom
          this.discussionRoom.on 'update', (data) =>
            if data.user_id != this.userId
              this.updateComments data
            return
        return

      updateComments: (data) ->
        this.page = data.page
        this.pagesize = data.pagesize
        this.totalpages = data.totalpages
        this.comments = data.comments

      getComments: (page) ->
        _this = this
        nanobar.go 20
        newpage = if page > 0 then page else 1
        $.ajax
          type: "POST"
          url: this.apiUrl('/getcomment')
          data:
            page: newpage
            pagesize: this.pagesize
          success: (data) ->
            if data.result
              _this.updateComments data
            else
              _this.showModal 'Failed to get messages'
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to get messages'

      addNewComment: () ->
        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/addcomment')
          data:
            text: this.newcomment
            pagesize: this.pagesize
          success: (data) ->
            if data.result
              _this.updateComments data
            else
              _this.showModal 'Failed to write new message'
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
        return false

      removeComment: (event) ->
        _this = this
        $comment = $(event.target)
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/removecomment')
          data:
            id: $comment.data('comment-id')
            page: this.page
            pagesize: this.pagesize
          success: (data) ->
            if data.result
              _this.updateComments data
            else
              _this.showModal 'Failed to remove message'
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to remove message'
        return false

# Dashboard -> Log
if $('#log-content').length > 0
  logVm = new BracketsDashboardPage

    el: '#log-content'

    data:

      logs: []

    ready: () ->
      this.getLogs 1

      return

    methods:

      moments: (date) ->
        return moment(date).format('MMMM Do YYYY, h:mm:ss a')

      updateLogs: (data) ->
        this.page = data.page
        this.pagesize = data.pagesize
        this.totalpages = data.totalpages
        this.logs = data.logs

      getLogs: (page) ->
        _this = this
        nanobar.go 20
        newpage = if page > 0 then page else 1
        $.ajax
          type: "POST"
          url: this.apiUrl('/getlogs')
          data:
            page: newpage
            pagesize: this.pagesize
          success: (data) ->
            if data.result
              _this.updateLogs data
            else
              _this.showModal 'Failed to get logs'
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to get logs'

# Dashboard -> Participants
if $('#participants-content').length > 0

  participantsVm = new BracketsDashboardPage

    el: '#participants-content'

    data:

      sortableAdjustment:
        left: 0
        top: 0
        startIndex: 0
      sortableArguments:
        onDragStart: ($item, container, _super, event) ->
          offset = $item.offset()
          pointer = container.rootGroup.pointer
          this.sortableAdjustment = {
            left: pointer.left - offset.left,
            top: pointer.top - offset.top
          }
          # this.startIndex = event.
          _super($item, container)
        onDrag: ($item, position) ->
          $item.css
            left: position.left - this.sortableAdjustment.left,
            top: position.top - this.sortableAdjustment.top
        onDrop: ($item, container, _super, event) ->
          participantsVm.reportSeeds()
          _super $item, container, event
          return true
      formData:
        user: ''
        users: ''
      byes: 0
      friends: ''
      players: []
      invites: []
      editable: false
      newName: ''

    ready: () ->
      this.getPlayers()
      this.getFriends()
      $('[data-toggle="tooltip"]').tooltip
        html: true

      return

    methods:

      _donone: () ->
        return

      getFriends: ->
        _this = this
        $.ajax
          type: "GET"
          url: '/friends'
          data:
            page: this.page
            limit: this.pagesize
            search: this.search_keyword
          success: (data) ->
            if data
              _this.page = data.current_page
              _this.last_page = data.last_page
              _this.friends = data.data
              console.log(data.data)
              console.log(_this.friends)
            else
              _this.showModal 'Failed to get friends'
          error: (response) ->
            _this.showModal 'Failed to get friends'

      addFriend: (friend) ->
        this.formData.user = friend
        this.addParticipant()

      paginationStart: () ->
        if this.page > 3
          if this.page + 2 > this.last_page
            if this.last_page <= 4
              return 1
            else
              return this.last_page - 4
          else
            return this.page - 2
        else
          return 1

      paginationEnd: () ->
        pgend = this.paginationStart() + 4
        if pgend > this.last_page
          pgend = this.last_page
        return pgend

      pageNumbers: () ->
        start = this.paginationStart()
        end = this.paginationEnd()
        pgn = []
        i = start
        while i <= end
          pgn.push i
          i++
        return pgn

      getPage: (page) ->
        this.page = page
        this.getFriends()

      onSearch: () ->
        this.page = 1
        this.getFriends()
        return

      refreshPlayerList: (data) ->
        this.players = data.players
        this.editable = data.editable
        this.invites = data.invites
        _this = this
        Vue.nextTick () ->
          _this.calculateByes()
          if _this.editable
            $('#players').sortable _this.sortableArguments

      getPlayers: () ->
        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/getplayers')
          success: (data) ->
            _this.refreshPlayerList data
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
        return false

      calculateByes: () ->
        playerCount = $('#players').children().length
        if playerCount > 0
          byes = playerCount - 1
          byes |= byes >> 1
          byes |= byes >> 2
          byes |= byes >> 4
          byes |= byes >> 8
          byes |= byes >> 16
          byes += 1
        else
          byes = 0
        this.byes = byes - playerCount

      shuffleSeeds: () ->
        if not this.editable
          return false
        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/shuffle')
          success: (data) ->
            _this.refreshPlayerList data
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
        return false

      reportSeeds: () ->
        if not this.editable
          return false
        players = new Array()
        $('#players').find('li').each (index, obj) ->
          $obj = $(obj)
          player =
            id: $obj.find('.card').data('id')
          players.push player
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/reportshuffle')
          data:
            players: players
          success: (data) ->
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
        return false

      addParticipant: () ->
        if not this.editable
          return false
        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/addparticipant')
          data:
            user: this.formData.user
          success: (data) ->
            nanobar.go 100
            if data.result
              _this.showModal 'User invited to the tournament!'
              _this.formData.user = ''
              _this.getPlayers()
            else
              _this.showModal 'Failed to add user to tournament. Username or email may not be correct'
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to invite user'
        return false

      addGuest: () ->
        if not this.editable
          return false
        _this = this
        nanobar.go 20
        $.ajax
          type: "POST"
          url: this.apiUrl('/addguest')
          data:
            guest: this.formData.guestPlayer
          success: (data) ->
            nanobar.go 100
            if data.result
              _this.showModal 'Guest player added to the tournament!'
              _this.formData.guestPlayer = ''
              _this.getPlayers()
            else
              _this.showModal 'Failed to add guest player to tournament.'
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to add guest player to tournament.'
        return false

      addParticipantsInBulk: () ->
        if not this.editable
          return false
        _this = this
        nanobar.go 20
        users = this.formData.users.split '\n'
        $.ajax
          type: "POST"
          url: this.apiUrl('/addparticipants')
          data:
            users: users
          success: (data) ->
            nanobar.go 100
            if data.invited > 0
              _this.showModal data.invited + ' users added or invited to the tournament!'
              _this.formData.users = ''
              _this.getPlayers()
            else
              _this.showModal 'Failed to invite any user to the tournament: usernames or emails not valid.'
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to invite users'
        return false

      changeCheckIn: (ev) ->
        if not this.editable
          return false
        _this = this
        id = $(ev.target).closest('.card-with-data-id').data 'id'
        $.ajax
          type: "POST"
          url: this.apiUrl('/changecheckin')
          data:
            id: id
          success: (data) ->
            if data.result
              if data.checked_in
                $(ev.target).removeClass('fa-clock-o').addClass('fa-check')
              else
                $(ev.target).removeClass('fa-check').addClass('fa-clock-o')
            else
              _this.showModal 'Failed to check-in player'
          error: (response) ->
            _this.showModal 'Failed to check-in player'
        return false

      openRenameDialog: (ev) ->
        if not this.editable
          return false
        this.renamingId = $(ev.target).closest('.card-with-data-id').data 'id'
        for index of this.players
          if this.players[index].id == this.renamingId
            this.newName = this.players[index].name
            break
        return false

      renamePlayer: (ev) ->
        if not this.editable
          return false
        _this = this
        nanobar.go 20
        id = this.renamingId
        $.ajax
          type: "POST"
          url: this.apiUrl('/renameplayer')
          data:
            id: id
            name: this.newName
          success: (data) ->
            if data.result
              for index of _this.players
                if _this.players[index].id == id
                  _this.players[index].name = _this.newName
                  break
            else
              _this.showModal 'Failed to rename the player team'
            nanobar.go 100
          error: (response) ->
            nanobar.go 100
            _this.showModal 'Failed to rename the player team'
        return false

      removePlayer: (ev) ->
        if not this.editable
          return false
        _this = this
        id = $(ev.target).closest('.card-with-data-id').data 'id'
        $.ajax
          type: "POST"
          url: this.apiUrl('/removeplayer')
          data:
            id: id
          success: (data) ->
            if data.result
              for index of _this.players
                if _this.players[index].id == id
                  _this.players.splice index, 1
                  break
            else
              _this.showModal 'Failed to remove player from the tournament'
          error: (response) ->
            _this.showModal 'Failed to remove player from the tournament'
        return false

# My tournaments page
if $('#index-content').length > 0
  myTournamentsVm = new BracketsDashboardPage

    el: '#index-content'

    data:
      searchKeyword: ''
      statusFilter: 'all'
      gameFilter: 'all'
      tournaments: []
      statistics: {}
      userFilter: false

    ready: () ->
      this.getTournaments();
      return

    methods:

      updateData: (res) ->
        this.page = res.current_page
        this.totalpages = res.last_page
        this.tournaments = res.data
        this.statistics = res.statistics

      getTournaments: () ->
        if $(this.$el).hasClass('all') and !this.userFilter
          apiPath = '/bracketsapi/all'
        else
          apiPath = '/bracketsapi/my'
        apiPath += '/page/' + this.page
        apiPath += '/limit/' + this.pagesize
        apiPath += '/status/' + this.statusFilter
        if this.searchKeyword
          apiPath += ('/search/' + this.searchKeyword)
        gameFilter = this.gameFilter
        gameFromUrl = ''
        gameCodeElement = document.getElementById('tournament-gamecode')
        if gameCodeElement
          gameFromUrl = gameCodeElement.value
        if gameFromUrl
          gameFilter = gameFromUrl

        # call api
        nanobar.go 20
        _this = this
        $.ajax
          type: "GET"
          url: apiPath
          data:
            game: gameFilter
          success: (res) ->
            nanobar.go 100
            if res.data
              _this.updateData res
            else
              _this.showModal 'Failed to get tournaments data from server'
          error: (res) ->
            nanobar.go 100
            _this.showModal 'Failed to get tournaments data from server'
        return

      getPage: (page) ->
        this.page = page
        this.getTournaments()
        return

      onStatusChanged: () ->
        this.getPage(1)
        return
