"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true

subscribeRoom = (subroom) ->
  window.room = broadcaster.subscribe(subroom)
  return

###
Activity Listing Component
###

Vue.component 'activity-list',
  template: '#activity-list-template'
  props: ['aid']

  methods:

    readActivity: (e, activity) ->
      console.log e.currentTarget
      $(e.currentTarget).parent().toggleClass('magictime puffOut').fadeOut()
      postData = {
        id: activity
      }
      $.ajax
        type: "POST"
        url: '/activity/read?activity_id=' + activity
        data: postData
        success: (data) ->
          if data.result == 200
            console.log('Successfully read activity')
            setTimeout (->
              EFROOT.getCurrentUser()
              return
            ), 1000
          else
            console.log('Error:', data.result)
        error: (response) ->
          if response.responseJSON.result == 'error'
            alert response.responseJSON.error
          else
            console.log response
      return false

###
ef-container
Main Sidebar - User feed
###

if $('#ef-container').length > 0
  EFROOT = new Vue
    el: '#ef-container'

    data: ->
      currentUser: {}
      firstLoad: true

    created: () ->
      this.getCurrentUser()
      #this.goActivity()
      return

    computed:

      userGem: ->
        if this.currentUser.hasOwnProperty('gem_balance')
          this.currentUser.gem_balance = parseInt(this.currentUser.gem_balance)
          return this.currentUser.gem_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        else
          return '0'

      userCoin: ->
        if this.currentUser.hasOwnProperty('gold_balance')
          return this.currentUser.gold_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        else
          return ''

      userBalance: ->
        if this.currentUser.hasOwnProperty('balance')
          return this.currentUser.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        else
          return ''

      challengerCount: ->
        if this.currentUser.hasOwnProperty('activities')
          if this.currentUser.activities.hasOwnProperty('challenger')
            return this.currentUser.activities.challenger.length
          else
            return ''

      tournamentsCount: ->
        if this.currentUser.hasOwnProperty('activities')
          if this.currentUser.activities.hasOwnProperty('tournaments')
            return this.currentUser.activities.tournaments.length
          else
            return ''

      treksCount: ->
        if this.currentUser.hasOwnProperty('activities')
          if this.currentUser.activities.hasOwnProperty('treks')
            return this.currentUser.activities.treks.length
          else
            return ''

      fantasiesCount: ->
        if this.currentUser.hasOwnProperty('activities')
          if this.currentUser.activities.hasOwnProperty('fantasies')
            return this.currentUser.activities.fantasies.length
          else
            return ''

      friendsCount: ->
        if this.currentUser.hasOwnProperty('activities')
          if this.currentUser.activities.hasOwnProperty('friends')
            return this.currentUser.activities.friends.length
          else
            return ''

    watch:
      'currentUser.id': ->
        if this.firstLoad == true
          this.firstLoad = false
          subscribeRoom(this.currentUser.id)
          this.watchSockets()
          return
        return

    methods:

      goActivity: () ->
        _this = this
        offset = $("a[data-link='ef-menu-user-contests']").offset()
        $('.activity-alert').animate {
          top: offset.top
        }, 1500, ->
          $(this).delay(2000).fadeOut 500, ->
            $(this).css({'top':'-200px', 'display':'block'})
            _this.goActivity()
            return

      notifyMe: () ->
        if Notification.permission != 'granted'
          Notification.requestPermission()
        else
          notification = new Notification('New Activity!',
            icon: ''
            body: 'You have a new activity notification on EFANTZ!')

          notification.onclick = ->
            window.open 'http://giuglu.com/'
            return

        return

      watchSockets: (e) ->
        _this = this
        if window.room
          window.room.on 'update', (data) ->
            _this.getCurrentUser()
            _this.notifyMe()
            console.log 'socket', data
            return

      updateUser: (data) ->
        this.currentUser = data

      getCurrentUser: ->
        _this = this
        #$('.preloader').addClass 'visible'
        $.ajax
          type: "GET"
          url: '/api/user'
          success: (data) ->
            if data
              _this.updateUser data
            else
              console.log 'Failed to get user'
            #$('.preloader').removeClass 'visible-o'
          error: (response) ->
            console.log response
