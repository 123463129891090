window.nanobar = new Nanobar()
Vue.http.headers.common['X-CSRF-TOKEN'] = $('meta[name="csrf-token"]').attr('content')

document.addEventListener 'DOMContentLoaded', ->
  if !Notification
    alert 'Desktop notifications are not available in your browser.'
    return
  if Notification.permission != 'granted'
    Notification.requestPermission()
  return

$ ->

  $(".chosen").chosen()

  $('.datetime-picker').datetimepicker
    format: 'MM dd, yyyy  HH:ii p'
    showMeridian: true

 ################ Accordian

  # Select Active on Load
  aIndex = $('.tabParent li.active').index();
  $('.tabChild').not(':eq(' + aIndex + ')').hide();

  # Events
  $('.tabParent > li').click ->
  	$('.tabParent li').removeClass('active')
  	$(this).addClass('active')
  	$('.tabChild').slideUp().eq($(this).index()).slideDown()


 ################ Refactor
$ ->

  if $('#utimezone').length
    $('#utimezone').val(moment.tz.guess())
    console.log moment.tz.guess()
    console.log $('#utimezone').val()

  if $('.hotness').length
    phrases = [
      'wreckin it',
      'kicking ass',
      'ruling',
      'dominating',
      'demolishing',
      'punishing',
      'devastating'
    ]
    $('.hotness').typed
      strings: phrases
      typeSpeed: 200
      startDelay: 0
      loop: true

  ### Main Menu
  ---------------------------------------
  ###

  ### Utility
  ---------------------------------------
  ###

  checkCookie = ->
    user = getCookie('username')
    if user != ''
      alert 'Welcome again ' + user
    else
      user = prompt('Please enter your name:', '')
      if user != '' and user != null
        setCookie 'username', user, 365
    return

  setCookie = (cname, cvalue, exdays) ->
    d = new Date
    d.setTime d.getTime() + exdays * 24 * 60 * 60 * 1000
    expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + '; ' + expires
    return

  getCookie = (cname) ->
    name = cname + '='
    ca = document.cookie.split(';')
    i = 0
    while i < ca.length
      c = ca[i]
      while c.charAt(0) == ' '
        c = c.substring(1)
      if c.indexOf(name) == 0
        return c.substring(name.length, c.length)
      i++
    ''

  do ->

    @EF_Menu = ->
      settings =
        state:  if getCookie('menustate') then getCookie('menustate') else 'open'
        state: 'closed'
        container: $('#ef-container')
        panelContainer: $('.ef-menu-inner')
        toggleClass: 'ef-toggled'
        activateClass: '.ef-menu-outer a'
        activeClass: 'ef-menu-active'
        prevTarget: ''

      if settings.state == 'open'
        settings.container.addClass settings.toggleClass
      else
        settings.container.removeClass settings.toggleClass

      $(settings.activateClass).click (e) ->
        e.preventDefault()
        target = $(this).attr('data-link')
        if settings.state == 'open'
          if target != settings.prevTarget
            $('#' + settings.prevTarget).fadeOut()
            $('#' + target).fadeIn()
            $(settings.activateClass).removeClass settings.activeClass
            $(this).addClass settings.activeClass
            settings.prevTarget = target
          else
            settings.panelContainer.fadeOut()
            $(settings.activateClass).removeClass settings.activeClass
            settings.container.removeClass settings.toggleClass
            settings.state = 'closed'
            setCookie('menustate', settings.state);
        else if settings.state == 'closed'
          $('#' + target).fadeIn()
          settings.container.addClass settings.toggleClass
          settings.state = 'open'
          setCookie('menustate', settings.state);
          $(this).addClass settings.activeClass
          settings.prevTarget = target
        return
      return

    return
  efMenu = new EF_Menu

  ### Scraps / Refactor this stuff <-- one day...
  ---------------------------------------
  ###

  checkMobile = ->
    if $('#chat-cont').length > 0
      if $(window).width() <= 1199
        $('.ef-body, #chat-cont').removeClass 'chat-open'
      if $(window).width() >= 1200
        $('.ef-body, #chat-cont').addClass 'chat-open'

    open = false;
    $('.mm-logo').click (e) ->

      if $(window).width() <= 1199
        e.preventDefault()

      if open
        $(this).removeClass('open')
        $('.main-menu ul').animate({top: $(window).height() * -1}, 500)
        open = false
      else
        $(this).addClass('open')
        $('.main-menu ul').animate({top: 0}, 500)
        open = true

    return

  $(window).resize ->
    setTimeout (->
      checkMobile()
    ), 300
    return

  checkMobile()

  $('.promo').hover (->
    $('.btn-go', this).animate {
      right: '0'
    }, 500
    return
  ), ->
    $('.btn-go', this).animate {
      right: '-100px'
    }, 500
    return

  # ADD SLIDEDOWN ANIMATION TO DROPDOWN //
  $('.dropdown').on 'show.bs.dropdown', (e) ->
    $(this).find('.dropdown-menu').first().stop(true, true).slideDown()
    #$(this).find('.dropdown-menu').first().stop(true, true).toggleClass 'magictime slideDown'
    return

  $('.dropdown').on 'hide.bs.dropdown', (e) ->
    $(this).find('.dropdown-menu').first().stop(true, true).slideUp()
    #$(this).find('.dropdown-menu').first().stop(true, true).toggleClass 'magictime slideDown'
    return

  # Chat Open / Close
  $('.chat-close').click ->
    $('.ef-body, #chat-cont').toggleClass 'chat-open'
    return

  # Highlight Rank
  if $('.rank').length > 0
    allPrev = $(".rank[data-rank='" + $('#rank').html() + "']").parent().prevAll()
    allPrev.each (i) ->
      $(this).find('.rank').addClass('rank-done')
    $(".rank[data-rank='" + $('#rank').html() + "']").attr('id', 'current-rank')

  ### Datatables
  ---------------------------------------
  ###

  #$('#ef-data-table').DataTable()

  ### Bootstrap Tooltips
  ---------------------------------------
  ###
  $('body').tooltip selector: '[data-toggle="tooltip"]'
  #$('[data-toggle="tooltip"]').tooltip()

  return
