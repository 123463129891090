"use strict";

Vue.config.delimiters = ['${', '}']
Vue.config.unsafeDelimiters = ['${[', '}]']
Vue.config.debug = true

subscribeRoom = (subroom) ->
  console.log(subroom)
  window.room = broadcaster.subscribe(subroom)
  return

###
Marketplace item component
###

Vue.component 'marketplace-item',
  template: '#marketplace-item'
  props: ['id']

  data: ->
    marketplaceItem: {}

  created: () ->
    this.getMarketplaceItem()
    return

  methods:

    showModal: (message) ->
      $modal = $('.ef-alert-modal')
      $modal.find('.modal-body').html message
      $modal.modal()
      return

    updateMarketplaceItem: (data) ->
      this.marketplaceItem = data

    getMarketplaceItem: () ->
      _this = this
      $.ajax
        type: "GET"
        url: '/api/product/' + _this.id
        success: (data) ->
          if data
            _this.updateMarketplaceItem data
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response

    purchaseItem: (requestId, itemId, userId) ->
      _this = this
      $.ajax
        type: "POST"
        url: '/api/marketplace'
        data: {
          id: requestId
          item_id: itemId
          user_id: userId
        }
        success: (data) ->
          if data.result == 200
            console.log(data.result)
            console.log('Successfully purchased item')
            setTimeout (->
              window.location = '/marketplace-success'
              return
            ), 800
          else
            _this.showModal '<div style="text-align:center;"><img style="display: block; width: 200px; margin: 0 auto;" src="/img/icons/activity/enrage.svg" /><br /><h3>You are nearly there!</h3>You don\'t have enough coin to pick up this item just yet. Keep on playing!<div>'
        error: (response) ->
          console.log 'Error: ' + response
      return false

###
Marketplace listing component
###

Vue.component 'marketplace-list',
  template: '#marketplace-list'

  data: ->
    marketplaceItems: {}

  created: () ->
    this.getMarketplaceItems()
    return

  computed:
    userCoin: ->
      if this.currentUser.hasOwnProperty('gold_balance')
        return this.currentUser.gold_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      else
        return ''

  methods:

    updateMarketplaceItems: (data) ->
      this.marketplaceItems = data

    getMarketplaceItems: () ->
      _this = this
      $.ajax
        type: "GET"
        url: '/api/products'
        success: (data) ->
          if data
            _this.updateMarketplaceItems data
        error: (response) ->
          if response.responseJSON.result == 'error'
            _this.showModal response.responseJSON.error
          else
            console.log response

###
Marketplace
###
if $('#marketplace').length > 0
  challenger = new Vue
    el: '#marketplace'
